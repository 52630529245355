import Cookies from "js-cookie";
import {
  makeRequestDelete2,
  makeRequestGet2,
  makeRequestPost2,
  makeRequestPut2,
} from "../api";

function getToken() {
  return new Promise((resolve) => {
    const checkToken = () => {
      let token = Cookies.get("token");
      if (token) {
        resolve(token);
      } else {
        setTimeout(checkToken, 100); // Check again after 100ms
      }
    };
    checkToken();
  });
}

export async function getPartners() {
  let token = await getToken();
  return makeRequestGet2(`partners/`, token);
}

export async function getPartner(id) {
  let token = await getToken();
  return makeRequestGet2(`partners/${id}`, token);
}

export async function postPartner(data) {
  let token = await getToken();
  return makeRequestPost2(`partners/`, data, token);
}

export async function registerPartner(data) {
  return makeRequestPost2(`partners1/register`, data);
}
export async function getPartnersByPeriod(data) {
  let token = await getToken();
  return makeRequestPost2(`partners/partnersperiod`, data, token);
}
export async function getPartnersByOfferId(data) {
  let token = await getToken();
  return makeRequestPost2(`partners/partnersofferid`, data, token);
}

export async function putPartner(id, data) {
  let token = await getToken();
  return makeRequestPut2(`partners/${id}`, data, token);
}

export async function deletePartner(id, data) {
  let token = await getToken();
  return makeRequestDelete2(`partners/${id}`, data, token);
}
