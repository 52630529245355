import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Box,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Button,
  Skeleton,
} from "@mui/material";
import HeaderClient from "../../components/headerClient";
import NavClientHorizontal from "../../components/navClientHorizontal";
import NavClient from "../../components/navClient";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import "../../App.css";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import feira from "../../images/example/mmachado.jpeg";
import { useTranslation } from "react-i18next";
import { getUser } from "../../api/requests/User";
import { getEvents } from "../../api/requests/Event";
import { getZones } from "../../api/requests/Data";
import locationIconSmall from "../../images/eventIcons/locationIconSmall.svg";
import calendarIconSmall from "../../images/eventIcons/calendarIconSmall.svg";
import { getPartners } from "../../api/requests/Partner";
import phoneIconSmall from "../../images/eventIcons/phoneIconSmall.svg";
import Star from "@mui/icons-material/Star";
import StarBorder from "@mui/icons-material/StarBorder";

function FavoritePartnersList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const userid = Cookies.get("id");
  const [loading, setLoading] = useState(true);

  const [name, setName] = useState(0);

  const [partners, setPartners] = useState([]);

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");
    const nameU = Cookies.get("first_name");

    if (!id && !token) {
      navigate("/loginclient");
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [favoritePartners, setFavoritePartners] = useState([]);
  useEffect(() => {
    getUser(userid)
      .then((res) => {
        if (res.status === 200 && res.success) {
          const data = res.data;
          const favorites = data.favorite_partner || [];
          setFavoritePartners(favorites);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      });
  }, [userid]);
  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");
    const nameU = Cookies.get("first_name");
    setName(nameU);

    //If don't have an ID or token
    if (!id && !token) {
      navigate("/loginclient");
      //Remove the data and forward to the Login page
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }

    getPartners()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPartners(res.data);

          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  return (
    <div className="background">
      <HeaderClient />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: isMobile? "4rem":"2rem",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <a className="greenTitleClientData">{t("favoritePartners")}</a>
        <div className="centerContent2">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 25,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Grid container spacing={2}>
              {loading
                ? Array.from(new Array(18)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                      <Skeleton
                        variant="rectangular"
                        height="10.625rem"
                        sx={{
                          borderRadius: "0.9375rem",
                          width: { xs: "82%", sm: "22.5625rem" },
                          margin: "0 0",
                        }}
                      />
                    </Grid>
                  ))
                : partners
                    .filter((partner) => favoritePartners.includes(partner._id))
                    .map((partner) => (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                        key={partner._id}
                      >
                        <div
                          className="event-container"
                          onClick={() =>
                            navigate("/partnerpage", {
                              state: { partnerId: partner._id },
                            })
                          }
                        >
                          <div
                            className="event-image2"
                            style={{
                              backgroundImage: `url(${partner.photo})`,
                            }}
                          ></div>
                          <div className="event-details">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",
                                overflow: "hidden",
                              }}
                            >
                              <div className="event-title-name2">
                                {partner.name}
                              </div>
                              {/* <div className="event-date">
                                         <div className="event-day-dark">
                                           {new Date(event.start_date).getDate()}
                                         </div>
                                         <div className="event-month-dark">
                                           {new Date(event.start_date).toLocaleString(
                                             "default",
                                             { month: "short" }
                                           )}
                                         </div>
                                       </div> */}
                            </div>
                            <div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  width: "100%",
                                  marginTop: "1rem",

                                  overflow: "hidden",
                                }}
                              >
                                <span
                                  style={{
                                    width: 11,
                                    height: 16,
                                    zIndex: 99,
                                    backgroundImage: `url(${locationIconSmall})`,
                                  }}
                                />
                                <div
                                  className="cardContent"
                                  style={{ marginLeft: 10, overflow: "hidden" }}
                                >
                                  {/* <a className="eventTextSmall">
                                             {event.station}
                                           </a> */}
                                  <a
                                    className="eventTextSmall"
                                    style={{ width: "7rem" }}
                                  >
                                    {partner.address}
                                  </a>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-start",
                                  alignItems: "center",
                                  flexDirection: "row",
                                  width: "100%",
                                  marginTop: 10,
                                }}
                              >
                                <span
                                  style={{
                                    width: 11,
                                    height: 11,
                                    zIndex: 99,
                                    backgroundImage: `url(${phoneIconSmall})`,
                                  }}
                                />
                                <div
                                  className="cardContent"
                                  style={{ marginLeft: 10 }}
                                >
                                  <a
                                    className="eventTextSmall"
                                    style={{ width: "7rem" }}
                                  >
                                    {partner.contact}
                                  </a>
                                </div>
                                <Box
                                  sx={{
                                    cursor: "pointer",
                                    marginTop: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: "20px",
                                  }}
                                >
                                  {favoritePartners.includes(partner._id) ? (
                                    <Star sx={{ color: "#FFD700" }} />
                                  ) : (
                                    <StarBorder sx={{ color: "#FFD700" }} />
                                  )}
                                </Box>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    ))}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FavoritePartnersList;
