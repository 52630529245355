import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { getPayments } from "../../api/requests/Payments";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getStatus } from "../../api/requests/Status";
import i18next from "i18next";
import { getPlans } from "../../api/requests/PaymentPlans";
import NavAdminMobile from "../../components/navAdminHorizontal";

function PartnerPlansAdmin() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/dashboardadmin");
  }
  const [payments, setPayments] = useState([]);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [plans, setPlans] = useState([]);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/loginadmin");
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }
    getPayments()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPayments(res.data);
          setPaymentsCopy(res.data);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getPlans()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPlans(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getStatus()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setStatus(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const [paymentsCopy, setPaymentsCopy] = useState([]);
  const [search, setSearch] = useState("");

  const StateCell = ({ value }) => {
    const isPaid = value.status == 4 ? true : false;

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "left",
          height: "100%",
        }}
      >
        <a
          style={{
            backgroundColor: isPaid ? "#13BB56" : "#ED4F2C",
            color: "#FFF",
            borderRadius: "5px",
            textAlign: "center",
            fontFamily: "Montserrat",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            width: "120px",
            height: "40px",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {status.find((element) => element.id === value.status)?.[
            i18next.language
          ] || ""}
        </a>
      </div>
    );
  };

  const DateCell = ({ value, row }) => {
    const isPaid = row.payment_date != null ? "paid" : "notpaid";
    const date = new Date(row.limit_date);

    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    return (
      <div
        style={{
          color: isPaid ? "black" : "#ED4F2C",
        }}
      >
        {formattedDate}
      </div>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop:isMobile?"0px": "-50px",
          marginBottom: isMobile?"0px":"30px",
          marginRight: "0px",
        }}
      >
        {/* <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />  */}
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "clamp(12px, 2vw, 16px)",
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayPayments = paymentsCopy;
      arrayPayments = arrayPayments.filter((element) =>
        element.owner_name.toLowerCase().includes(search.toLowerCase())
      );
      setPayments(arrayPayments);
    } else {
      setPayments(paymentsCopy);
    }
  }, [search]);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    type: !isSmallScreen,
    value: !isSmallScreen,
  });

  const handleResize = () => {
    const smallScreen = window.innerWidth < 1000;
    setIsSmallScreen(smallScreen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setColumnVisibilityModel({
      type: !isSmallScreen,
      value: !isSmallScreen,
    });
  }, [isSmallScreen]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <Header />
      {isMobile?<NavAdminMobile/>:<NavAdmin />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
        <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("plans")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: isMobile?15:45,
              marginLeft: isMobile?10:30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                className={"grid"}
                autoPageSize={true}
                columnVisibilityModel={columnVisibilityModel}
                loading={loading}
                columns={[
                  // {
                  //   field: "_id",
                  //   headerName: t("id"),
                  //   type: "number",
                  //   maxWidth: 70,
                  //   flex: 1,
                  // },
                  {
                    field: "name",
                    headerName: t("name"),
                    type: "string",
                    flex: 1,
                  //   headerAlign:"center",
                  // align:"center",
                  },

                  {
                    field: "duration",
                    headerName: t("duration"),
                    type: "string",
                    flex: 1,
                    headerAlign:"center",
                  align:"center",
                  },
                  {
                    field: "type",
                    headerName: t("type"),
                    type: "string",
                    flex: 1,
                    headerAlign:"center",
                  align:"center",
                    renderCell: (params) => {
                      const type = params.value;
                      let translatedType;

                      switch (type) {
                        case "monthly":
                          translatedType = t("monthly");
                          break;
                        case "yearly":
                          translatedType = t("yearly");
                          break;
                        case "trimestral":
                          translatedType = t("trimestral");
                          break;
                        default:
                          translatedType = type;
                      }

                      return <span>{translatedType}</span>;
                    },
                  },
                  {
                    field: "value",
                    headerName: t("value"),
                    flex: 1,
                    type: "string",
                    headerAlign:"center",
                  align:"center",
                    renderCell: (params) => {
                      const value = params.value || 0;
                      return `${value.toFixed(2)}€`;
                    },
                  },

                  {
                    field: "actions",
                    type: "actions",
                    headerName: t("actions"),
                    flex: 1,
                    cellClassName: "actions",
                    getActions: ({ id }) => {
                      return [
                        <GridActionsCellItem
                          icon={
                            <FontAwesomeIcon
                              style={{
                                fontSize: "clamp(12px, 1.5vw, 16px)",
                                color: "#0F1111",
                              }}
                              icon={faMagnifyingGlass}
                            />
                          }
                          label="Save"
                          onClick={() => {
                            navigate("/partnerplanedit", {
                              state: { planId: id },
                            });
                          }}
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  "& .MuiDataGrid-cell": {
                    fontSize: "clamp(12px, 1.5vw, 16px)",
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    fontSize: "clamp(14px, 2vw, 18px)",
                  },
                  "& .MuiDataGrid-footerContainer": {
                    fontSize: "clamp(12px, 1.5vw, 16px)",
                  },
                  "& .MuiTablePagination-root ": {
                    overflow: "hidden !important",
                  },
                }}
                rows={plans}
                checkboxSelection={true}
                onRowClick={(params) => {}}
                getRowId={(row) => row._id}
                localeText={
                  i18n.language == "pt"
                    ? ptPT.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  BaseCheckbox: ({ value, ...otherProps }) => (
                    <Checkbox size="small" checked={value} {...otherProps} />
                  ),
                  LoadingOverlay: <CircularProgress />,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="clientInfoEditButton"
              onClick={() => {
                navigate("/plancreateadmin");
              }}
            >
              {t("addPlan")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerPlansAdmin;
