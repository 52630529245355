import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "../../App.css";
import { useTranslation } from "react-i18next";

import NavClient from "../../components/navClient";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import ticketup from "../../images/ticket/ticketup.svg";
import doubleArrows from "../../images/ticket/doubleArrows.svg";
import repeat from "../../images/ticket/repeat.svg";
import go from "../../images/ticket/go.svg";
import multi from "../../images/ticket/multi.svg";
import { getTrainStations } from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import i18next from "i18next";
import {
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  FormControlLabel,
  Switch,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import NavClientHorizontal from "../../components/navClientHorizontal.js";

function TicketsClient() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [selectedButton, setSelectedButton] = useState("button1");
  const [trainstations, setTrainstations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm2, setSearchTerm2] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [fromStation, setFromStation] = useState("");
  const dropdownRef = useRef(null);
  const dropdownRef2 = useRef(null);

  const [open, setOpen] = useState(false);

  const [passengers, setPassengers] = useState({
    adults: 1,
    babies: 0,
    children: 0,
    seniors: 0,
  });
  const [mobilityReduced, setMobilityReduced] = useState(false);
  const [extraEquipment, setExtraEquipment] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleIncrement = (type) => {
    setPassengers((prev) => ({ ...prev, [type]: prev[type] + 1 }));
  };

  const handleDecrement = (type) => {
    setPassengers((prev) => ({
      ...prev,
      [type]: prev[type] > 0 ? prev[type] - 1 : 0,
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside2 = (event) => {
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsOpen2(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside2);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSelectChange2 = (station) => {
    setToStation(station.id);
    setIsOpen2(false);
    setSearchTerm2(station.designation);
  };
  const filteredStations = trainstations.filter(
    (station) =>
      station.designation.toLowerCase().includes(searchTerm.toLowerCase()) ||
      station.county.toLowerCase().includes(searchTerm.toLowerCase()) ||
      station.district.toLowerCase().includes(searchTerm.toLowerCase()) ||
      station.parish.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange2 = (event) => {
    setSearchTerm2(event.target.value);
  };

  const handleSelectChange = (station) => {
    setFromStation(station.id);
    setIsOpen(false);
    setSearchTerm(station.designation);
  };
  const filteredStations2 = trainstations.filter(
    (station) =>
      station.designation.toLowerCase().includes(searchTerm2.toLowerCase()) ||
      station.county.toLowerCase().includes(searchTerm2.toLowerCase()) ||
      station.district.toLowerCase().includes(searchTerm2.toLowerCase()) ||
      station.parish.toLowerCase().includes(searchTerm2.toLowerCase())
  );
  const [toStation, setToStation] = useState([]);
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today);

  const [selectedDate2, setSelectedDate2] = useState(today);
  const isWidgetAdded = useRef(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // 1 segundo para exibir o esqueleto

    return () => clearTimeout(timer);
  }, []);

  const widgetRef = useRef(null);

  useEffect(() => {
    if (loading && !isWidgetAdded.current) {
      const widgetStyle = document.createElement("link");
      widgetStyle.setAttribute("rel", "stylesheet");
      widgetStyle.setAttribute(
        "href",
        "https://www.omio.com/gcs-proxy/b2b-nemo-prod/bundle/pt/bundle.css?v=" +
          new Date().getTime()
      );
      document.head.appendChild(widgetStyle);

      const widgetScript = document.createElement("script");
      widgetScript.setAttribute(
        "src",
        "https://www.omio.com/gcs-proxy/b2b-nemo-prod/bundle/pt/bundle.js?v=" +
          new Date().getTime()
      );
      document.body.appendChild(widgetScript);

      widgetScript.onload = () => {
        // Lógica de inicialização do widget se necessário
        if (widgetRef.current) {
          const event = new CustomEvent("omioWidgetReady");
          widgetRef.current.dispatchEvent(event);
        }
      };

      isWidgetAdded.current = true;
    }
  }, [loading]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="background">
      <HeaderClientTransparent />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}

      <div className="backgroundImageDashboardClient"></div>
      <p className="TitleTicket">{t("buyticket")}</p>
      {/* {loading ? (
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "20%",
            height: "50%",
            margin: "0px auto",
            flexDirection: "column",
            display: "flex",
          }}
        >
          <Skeleton
            variant="rectangular"
            width="100%"
            height="40%"
            sx={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }}
          />
          <Skeleton
            variant="rectangular"
            width="100%"
            height="16px"
            sx={{ marginTop: "8px" }}
          />

          <Skeleton
            variant="rectangular"
            width="100%"
            height="60%"
            sx={{
              borderBottomLeftRadius: "15px",
              borderBottomRightRadius: "15px",
              marginTop: "8px",
            }}
          />
        </Box>
      ) : ( */}
      <div
        style={{
          alignItems: "center",
          justifyContent: "center",
          width: isMobile ? "70%" : "20%",
          display: "flex",
          height: "50%",
          margin: "0px auto",
          flexDirection: "column",
        }}
      >
        <div
          data-omio-widget="true"
          data-partner-id="tectank"
          data-theme="custom"
          data-new-tab="true"
          data-redirect="https://omio.sjv.io/c/5553316/569351/7385?url="
          // data-redirect="https://omio.sjv.io/trainsport"
        >
          <script type="application/json">
            {JSON.stringify({
              "primary-color": "grey",
              "secondary-color": "#14bb57",
              // "font-family": "Montserrat",
              // "font-size": "18px !important",
              border: "1px red !important",
              background: "white",
            })}
          </script>
        </div>

        {/* <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              borderRadius: "15px",
              backgroundColor: "#FFF",
              width: "40%",
              height: "55%",
              boxShadow: "0px 1px 2px 0px rgba(30, 30, 30, 0.15)",
            },
          }}
        >
          <DialogContent
            sx={{
              marginInline: "15%",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {["adults", "babies", "children", "seniors"].map((type, index) => (
              <Grid
                container
                alignItems="center"
                key={type}
                sx={{
                  marginBottom: "10px",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item xs={8}>
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "normal",
                    }}
                  >
                    {type === "adults"
                      ? t("adults")
                      : type === "babies"
                      ? t("babies")
                      : type === "children"
                      ? t("children")
                      : t("seniors")}
                  </div>
                  <div
                    style={{
                      fontFamily: "Montserrat",
                      fontSize: "12px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                      color: "#777",
                    }}
                  >
                    {type === "adults"
                      ? t("+16y")
                      : type === "babies"
                      ? t("0-2y")
                      : type === "children"
                      ? t("2-16y")
                      : t("+58y")}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={4}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={() => handleDecrement(type)}
                    sx={{ p: 1 }}
                    disabled={passengers[type] === 0}
                  >
                    <RemoveCircleIcon
                      sx={{
                        color: "#FFF",
                        fontSize: 20,
                        borderRadius: "50%",
                        backgroundColor:
                          passengers[type] === 0 ? "#0f111166" : "#13BB56",
                      }}
                    />
                  </IconButton>
                  <div
                    style={{
                      width: "15px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {passengers[type]}
                  </div>
                  <IconButton
                    onClick={() => handleIncrement(type)}
                    sx={{ p: 1 }}
                  >
                    <AddCircleIcon
                      sx={{
                        color: "#FFFF",
                        fontSize: 20,
                        borderRadius: "50%",
                        backgroundColor: "#13BB56",
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={mobilityReduced}
                    onChange={() => setMobilityReduced(!mobilityReduced)}
                    color="primary"
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#FFFFFF !important",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "#13BB56 !important",
                          opacity: "1 !important",
                        },
                    }}
                  />
                }
                label={t("mobilityReduced")}
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",

                  borderRadius: "4px",
                  padding: "10px",
                  marginBottom: "5px",
                  "& .MuiFormControlLabel-label": {
                    flexGrow: 1,
                    textAlign: "left",
                    marginLeft: "10px",
                    color: "#0F1111",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                  },
                }}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={extraEquipment}
                    onChange={() => setExtraEquipment(!extraEquipment)}
                    color="primary"
                    sx={{
                      "& .MuiSwitch-switchBase.Mui-checked": {
                        color: "#FFFFFF !important",
                      },
                      "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                        {
                          backgroundColor: "#13BB56 !important",
                          opacity: "1 !important",
                        },
                    }}
                  />
                }
                label={t("extraEquipment")}
                sx={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  borderRadius: "4px",
                  padding: "10px",
                  marginBottom: "10px",
                  "& .MuiFormControlLabel-label": {
                    flexGrow: 1,
                    textAlign: "left",
                    marginLeft: "10px",
                    color: "#0F1111",
                    fontFamily: "Montserrat",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                  },
                }}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <div className="bottomRowInfoClient">
              <button className="clientInfoEditButtonShort" onClick={() => {}}>
                {t("save")}
              </button>

              <button className="clientInfoCancelButtonShort">
                {" "}
                {t("cancel")}
              </button>
            </div>
          </DialogActions>
        </Dialog> */}
      </div>
      {/* )} */}
      <div className="bottomColumn">
        {/* <button className="clientInfoEditButtonShort" onClick={() => {}}>
          {t("tickets")}
        </button> */}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <a className="partnershipText">{t("parternship")}</a>
          <a
            className="partnershipText"
            href="https://www.omio.pt/sobre-nos"
            target="_blank"
            style={{ marginLeft: "2px" }}
          >
            {t("moreinfo")}
          </a>
        </div>
      </div>
    </div>
  );
}

export default TicketsClient;
