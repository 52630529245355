import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import "../../App.css";
import { useTranslation } from "react-i18next";
import fest from "../../images/example/fest.jpeg";
import card from "../../images/example/card.jpeg";

import NavClient from "../../components/navClient";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import locationIcon from "../../images/eventIcons/locationIcon.svg";
import calendarIcon from "../../images/eventIcons/calendarIcon.svg";
import x from "../../images/eventIcons/x.svg";
import youtube from "../../images/eventIcons/youtube.svg";
import instagram from "../../images/eventIcons/instagram.svg";
import facebook from "../../images/eventIcons/facebook.svg";
import {
  Modal,
  Backdrop,
  IconButton,
  Skeleton,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getEvent } from "../../api/requests/Event";
import toast from "react-hot-toast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getTrips, postTrips } from "../../api/requests/Trips";
import Cookies from "js-cookie";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import { Button, Rating, List, ListItem } from "@mui/material";

import dayjs from "dayjs";
import i18next from "i18next";
import { getTrainStations } from "../../api/requests/Data";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
  useMapsLibrary,
  useMap,
} from "@vis.gl/react-google-maps";
import { getUser, putFavoritesUser, putUser } from "../../api/requests/User";
import NavClientHorizontal from "../../components/navClientHorizontal";
import {
  getEvaluations,
  postEvaluations,
} from "../../api/requests/Evaluations";
import { getPartners } from "../../api/requests/Partner";

function EventPageClient() {
  const location = useLocation();
  const { eventId } = location.state || {};
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [event, setEvent] = useState([]);
  const [trips, setTrips] = useState([]);
  const trainstation = Cookies.get("favorite_station");
  const userid = Cookies.get("id");
  const [open2, setOpen2] = useState(false);
  const today = dayjs();
  const [selectedDate, setSelectedDate] = useState(today);
  const [passengers, setPassengers] = useState(1);
  const [selectedDate2, setSelectedDate2] = useState(today);
  const handleIncrement = () => {
    setPassengers((prev) => prev + 1);
  };

  const handleDecrement = () => {
    setPassengers((prev) => (prev > 0 ? prev - 1 : 0));
  };
  const [distance, setDistance] = useState(0);
  const [co2saved, setco2saved] = useState(0);
  const [defaultZoom, setDefaultZoom] = useState(10);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    borderRadius: "0.93rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleBackdropClick = (event) => {
    if (event.target === event.currentTarget) {
      handleClose();
    }
  };
  const [loading, setLoading] = useState(true);
  const [trainstations, setTrainstations] = useState([]);
  const [favstation, setFavstations] = useState([]);
  const [partners, setPartners] = useState([]);
  function calculateCo2Savings(distance) {
    const co2PerKmCar = 170; // gramas por km para carro
    const co2PerKmTrain = 6; // gramas por km para comboio

    // CO2 emitido se a viagem fosse feita de carro
    const co2Car = distance * co2PerKmCar;

    // CO2 emitido se a viagem for feita de comboio
    const co2Train = distance * co2PerKmTrain;

    // CO2 poupado (diferença entre carro e comboio)
    const co2Savings = co2Car - co2Train;

    return co2Savings; // Retorna as gramas poupadas
  }
  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });

    getPartners()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPartners(res.data);
          if (partners != []) {
            setLoading(false);
          }
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  useEffect(() => {
    getEvent(eventId)
      .then((res) => {
        if (res.status === 200 && res.success) {
          setEvent([res.data]);

          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });

    if (trainstation) {
      const favoriteStationId = trainstation.split(",")[0];

      getTrainStations()
        .then((res) => {
          if (res.status === 200 && res.success) {
            const trainstations = res.data;
            const favoriteStation = trainstations.find(
              (station) => station.id === parseInt(favoriteStationId)
            );

            // Verifique se encontrou a estação
            if (favoriteStation) {
              setFavstations(favoriteStation);
            } else {
              toast.error("Estação favorita não encontrada.");
            }
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error("500");
        });
    }
  }, []);

  function Directions() {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionsService] = useState(null);
    const [directionsRenderer, setDirectionsRenderer] = useState(null);
    const [routes, setRoutes] = useState([]);
    const [routeIndex, setRouteIndex] = useState(0);
    const selected = routes[routeIndex];
    const leg = selected?.legs[0];

    useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionsService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map]);

    // Use directions service
    useEffect(() => {
      if (!directionsService || !directionsRenderer) return;

      directionsService
        .route({
          origin: { lat: favstation.latitude, lng: favstation.longitude },
          destination: {
            lat: event[0].station[0].latitude,
            lng: event[0].station[0].longitude,
          },
          travelMode: window.google.maps.TravelMode.TRANSIT,
          transitOptions: {
            modes: ["TRAIN"],
          },
        })
        .then((response) => {
          directionsRenderer.setDirections(response);
          setRoutes(response.routes);
        });

      return () => directionsRenderer.setMap(null);
    }, [directionsService, directionsRenderer]);

    // Update direction route
    useEffect(() => {
      if (!directionsRenderer) return;
      directionsRenderer.setRouteIndex(routeIndex);
    }, [routeIndex, directionsRenderer]);

    if (!leg) return null;

    setDistance(leg.distance?.text);

    setco2saved(calculateCo2Savings(parseFloat(leg.distance?.text)));
  }
  function create() {
    if (passengers === 0) {
      toast.error(t("passengerValidation"));
      return;
    }

    postTrips({
      destination: parseInt(event[0].station[0].id, 10),
      origin: favstation.id,
      number_of_people: passengers,
      event_id: eventId,
      co2: co2saved,
      departure_date: selectedDate,
      return_date: selectedDate2,
      user_id: userid,
      distance: distance,
    })
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("successTrip"));

          getTrips()
            .then((res) => {
              if (res.status === 200 && res.success) {
                const userTrips = res.data.filter(
                  (trip) => trip.user_id === userid
                );

                setTrips(userTrips);

                putUser(userid, { trips: userTrips.map((trip) => trip._id) })
                  .then((res) => {
                    if (res.success && res.status === 200) {
                      toast.success(t("successMessage"));
                    } else {
                      // toast.error(res.error);
                    }
                  })
                  .catch((error) => {
                    // toast.error(t("500"));
                  });
              } else {
                toast.error(res.error);
              }
            })
            .catch((error) => {
              toast.error(t("500"));
            });
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [favoriteEvents, setFavoriteEvents] = useState([]);

  useEffect(() => {
    getUser(userid)
      .then((res) => {
        if (res.status === 200 && res.success) {
          const data = res.data;
          const favorites = data.favorite_events || [];
          setFavoriteEvents(favorites);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      });
  }, [userid]);

  const handleToggleFavorite = () => {
    if (!eventId) {
      toast.error(t("invalidEvent"));
      return;
    }

    // Atualizar os favoritos localmente
    const updatedFavorites = favoriteEvents.includes(eventId)
      ? favoriteEvents.filter((id) => id !== eventId)
      : [...favoriteEvents, eventId];

    setFavoriteEvents(updatedFavorites);

    // Fazer a chamada à API de forma independente
    putFavoritesUser(userid, { favorite_events: updatedFavorites })
      .then((response) => {
        if (response?.success) {
          toast.success(t("favoritesUpdatedSuccessfully"));
        } else {
          toast.error(response?.error || t("updateFailed"));
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  };
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    getEvaluations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          const filteredReviews = res.data.filter(
            (review) => review.type_id === eventId
          );
          setReviews(filteredReviews);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  // Estados

  const [newReview, setNewReview] = useState({
    datetime: new Date().toISOString(),
    rating: 0,
    obs: "",
    user_id: userid,
  });

  // Adicionar nova avaliação
  const handleSubmit = async () => {
    if (!newReview.rating || !newReview.obs) {
      toast.error(t("fillFields"));
      return;
    }

    try {
      const newEvaluation = {
        ...newReview,
        type_id: eventId,
        type: "event",
      };

      const response = await postEvaluations(newEvaluation);

      if (response && response.status === 200) {
        const createdEvaluation = response.data;

        setReviews((prev) => [createdEvaluation, ...prev]);
        setNewReview({ rating: 0, obs: "" });
        toast.success(t("reviewSuccess"));
      } else {
        toast.error(t("reviewFail"));
      }
    } catch (error) {
      toast.error(t("reviewFail"));
    }
  };

  return (
    <div className="background">
      <HeaderClientTransparent />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}
      <div className="backgroundImageDashboardClient"></div>
      <div className="topLeftBackTrip">
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon
            style={{
              fill: "#044535",
              zIndex: 99,
              fontSize: isMobile ? "20px" : "25px",
            }}
          />
        </IconButton>
      </div>
      {loading ? (
        <div className="skeletonDiv">
          <Skeleton
            height={"100%"}
            width={"100%"}
            sx={{ borderRadius: "1.0625rem 1.0625rem 0.5rem 0.5rem;" }}
            // className="eventbigcardloading"
          />
        </div>
      ) : (
        <>
          {event.map((event) => (
            <div className="scrollableTransparentDiv">
              <div className="eventbigcard">
                <img src={event.poster[0]} className="eventbigcardImage" />

                <div className="fullWidthCard">
                  <div className="cardContent">
                    <a className="eventTitle">{event.name}</a>
                    <div className="infoRow">
                      <span
                        style={{
                          width: 20.53,
                          height: 30,
                          backgroundImage: `url(${locationIcon})`,
                        }}
                      />
                      <div className="cardContent">
                        <a className="eventText">
                          {event.station[0].designation}
                        </a>
                        <a className="eventText">
                          {event.district[0].pt}, Portugal
                        </a>
                      </div>
                      <span
                        style={{
                          width: 26.68,
                          height: 30,
                          backgroundImage: `url(${calendarIcon})`,
                          marginLeft: "15%",
                        }}
                      />
                      <div className="cardContent">
                        <a className="eventText">
                          {new Date(event.start_date).toLocaleDateString()} |{" "}
                          {new Date(event.end_date).toLocaleDateString()}
                        </a>
                        <a className="eventText">
                          {event.district[0].pt}, Portugal
                        </a>
                      </div>

                      <div className="cardContentRight">
                        {event.price_data.length > 0 ? (
                          <div className="priceCard">
                            <a className="sinceText">{t("since")}</a>
                            <a className="valueText">{event.price_data[0]}</a>
                          </div>
                        ) : (
                          <div className="priceCard">
                            {/* <a className="sinceText">{t("since")}</a> */}
                            <a className="freevalueText">{t("free")}</a>
                          </div>
                        )}
                        <Box
                          sx={{
                            cursor: "pointer",
                            marginTop: isMobile ? "20px" : "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "10px",
                          }}
                          onClick={handleToggleFavorite}
                        >
                          {favoriteEvents.includes(eventId) ? (
                            <Favorite sx={{ color: "#E53935" }} />
                          ) : (
                            <FavoriteBorder sx={{ color: "#E53935" }} />
                          )}
                        </Box>

                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            marginTop: "10px",
                            marginRight: "10px",
                          }}
                        >
                          <span
                            style={{
                              width: 22,
                              height: 22,
                              backgroundImage: `url(${instagram})`,
                              marginInline: "4px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              window.open(`${event.url_instagram}`, "_blank")
                            }
                          />
                          <span
                            style={{
                              width: 22,
                              height: 22,
                              backgroundImage: `url(${facebook})`,
                              marginInline: "4px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              window.open(`${event.url_facebook}`, "_blank")
                            }
                          />
                          <span
                            style={{
                              width: 22,
                              height: 22,
                              backgroundImage: `url(${x})`,
                              marginInline: "4px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              window.open(`${event.url_twitter}`, "_blank")
                            }
                          />
                          <span
                            style={{
                              width: 22,
                              height: 22,
                              backgroundImage: `url(${youtube})`,
                              marginInline: "4px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              window.open(`${event.url_youtube}`, "_blank")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="infoEventCard">
                <img
                  src={event.poster[0]}
                  className="eventcardSmall"
                  onClick={handleOpen}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="event-card-modal"
                  aria-describedby="event-card-description"
                  BackdropComponent={Backdrop}
                  BackdropProps={{ timeout: 500, onClick: handleBackdropClick }}
                >
                  <div className="modal-wrapper">
                    <div className="modal-content">
                      <IconButton
                        onClick={handleClose}
                        sx={{ position: "absolute", right: 10, top: 10 }}
                      >
                        <CloseIcon
                          style={{ color: "white", borderRadius: 20 }}
                        />
                      </IconButton>
                      <div className="image-container">
                        <img src={event.poster[0]} className="modal-image" />
                      </div>
                    </div>
                  </div>
                </Modal>

                <div className="cardContentInfo" style={{ marginLeft: 40 }}>
                  <a className="eventInfoTitle">{t("description")}</a>
                  <a className="eventInfoText description">{event.obs}</a>

                  <div className="textRow">
                    <div className="cardContentInfo">
                      <a className="eventInfoTitle">{t("schedule")}</a>
                      <a className="eventInfoText">
                        {new Date(event.start_date).toLocaleString([], {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                        h <br></br>
                        {new Date(event.end_date).toLocaleString([], {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                        h
                      </a>
                    </div>
                    <div className="cardContentInfo">
                      <a className="eventInfoTitle">{t("local")}</a>
                      <a className="eventInfoText">{event.district[0].pt}</a>
                    </div>
                    <div className="cardContentInfo">
                      <a className="eventInfoTitle">{t("nearStation")}</a>
                      <a className="eventInfoText">
                        {event.station[0].designation},<br></br>{" "}
                        {event.district[0].pt}
                      </a>
                    </div>
                    <Modal open={open2} onClose={handleClose2}>
                      <Box sx={style}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            fontFamily: "Montserrat !important",
                          }}
                        >
                          <Typography
                            variant="h6"
                            component="h2"
                            sx={{ fontFamily: "Montserrat, sans-serif" }}
                          >
                            {t("igotrain2")}
                          </Typography>

                          <IconButton
                            onClick={handleClose2}
                            aria-label="fechar"
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                        <div
                          style={{
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <a className="photoLabel">{t("go")} *</a>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18next?.language}
                          >
                            <DateTimePicker
                              variant="standard"
                              showTimeSelect
                              value={selectedDate}
                              onChange={(e) => {
                                setSelectedDate(e);
                              }}
                              slotProps={{
                                tabs: {
                                  hidden: false,
                                },

                                day: {
                                  sx: {
                                    "&.MuiPickersDay-root.Mui-selected": {
                                      backgroundColor: "#13BB56 !important",
                                    },
                                  },
                                },

                                desktopPaper: {
                                  sx: {
                                    borderRadius: "8px",
                                    ".MuiPickersYear-yearButton.Mui-selected": {
                                      backgroundColor: "#13BB56 !important",
                                    },
                                    ".MuiPickersMonth-monthButton.Mui-selected":
                                      {
                                        backgroundColor: "#13BB56 !important",
                                      },
                                    "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                      {
                                        backgroundColor: "#13BB56 !important",
                                      },
                                    "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                      {
                                        color: "#13BB56 !important",
                                      },
                                    "& .css-1aquho2-MuiTabs-indicator": {
                                      backgroundColor: "#13BB56 !important",
                                    },
                                    "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                      {
                                        color: "#13BB56 !important",
                                      },
                                  },
                                },
                              }}
                              sx={{
                                width: isMobile ? "100%" : "60%",

                                "& .MuiInputBase-input.Mui-disabled": {
                                  backgroundColor: "white",
                                },
                                "& .MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: "#44b887 !important",
                                  color: "white",
                                },
                                "& .MuiPickersCalendar-root": {
                                  borderRadius: "8px !important",
                                },

                                "& .MuiInputLabel-root ": {
                                  color: "#044535 !important",

                                  fontFamily: "Montserrat",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "15px",
                                  borderColor: "transparent !important",
                                  position: "absolute",
                                  marginTop: "-5px",
                                  marginLeft: "-10px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  color: "#0F111199 !important",
                                  textAlign: "right",
                                  fontFamily: "Montserrat",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "15px",
                                  borderRadius: "8px",
                                  // background: !edicao ? "#0f11110d" : "white",
                                  borderColor: "transparent !important",
                                  width: "100%",
                                  height: "34.88px",
                                  padding: "16.5px 10px",
                                },
                                "& .MuiInputLabel-root.Mui-disabled ": {
                                  color: "#044535 !important",
                                  fontFamily: "Montserrat",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "15px",
                                  borderColor: "transparent !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline ": {
                                  border: "0px",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "16.5px 0px",
                                  width: "100%",
                                },
                              }}
                            ></DateTimePicker>
                          </LocalizationProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <a className="photoLabel">{t("back")} *</a>
                          <LocalizationProvider
                            dateAdapter={AdapterDayjs}
                            adapterLocale={i18next?.language}
                          >
                            <DateTimePicker
                              variant="standard"
                              showTimeSelect
                              value={selectedDate2}
                              onChange={(e) => {
                                setSelectedDate2(e);
                              }}
                              slotProps={{
                                tabs: {
                                  hidden: false,
                                },

                                day: {
                                  sx: {
                                    "&.MuiPickersDay-root.Mui-selected": {
                                      backgroundColor: "#13BB56 !important",
                                    },
                                  },
                                },

                                desktopPaper: {
                                  sx: {
                                    borderRadius: "8px",
                                    ".MuiPickersYear-yearButton.Mui-selected": {
                                      backgroundColor: "#13BB56 !important",
                                    },
                                    ".MuiPickersMonth-monthButton.Mui-selected":
                                      {
                                        backgroundColor: "#13BB56 !important",
                                      },
                                    "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                      {
                                        backgroundColor: "#13BB56 !important",
                                      },
                                    "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                      {
                                        color: "#13BB56 !important",
                                      },
                                    "& .css-1aquho2-MuiTabs-indicator": {
                                      backgroundColor: "#13BB56 !important",
                                    },
                                    "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                      {
                                        color: "#13BB56 !important",
                                      },
                                  },
                                },
                              }}
                              sx={{
                                width: isMobile ? "100%" : "60%",

                                "& .MuiInputBase-input.Mui-disabled": {
                                  backgroundColor: "white",
                                },
                                "& .MuiPickersDay-root.Mui-selected": {
                                  backgroundColor: "#44b887 !important",
                                  color: "white",
                                },
                                "& .MuiPickersCalendar-root": {
                                  borderRadius: "8px !important",
                                },

                                "& .MuiInputLabel-root ": {
                                  color: "#044535 !important",

                                  fontFamily: "Montserrat",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "15px",
                                  borderColor: "transparent !important",
                                  position: "absolute",
                                  marginTop: "-5px",
                                  marginLeft: "-10px",
                                },
                                "& .MuiOutlinedInput-root": {
                                  color: "#0F111199 !important",
                                  textAlign: "right",
                                  fontFamily: "Montserrat",
                                  fontSize: "18px",
                                  fontStyle: "normal",
                                  fontWeight: 500,
                                  lineHeight: "15px",
                                  borderRadius: "8px",
                                  // background: !edicao ? "#0f11110d" : "white",
                                  borderColor: "transparent !important",
                                  width: "100%",
                                  height: "34.88px",
                                  padding: "16.5px 10px",
                                },
                                "& .MuiInputLabel-root.Mui-disabled ": {
                                  color: "#044535 !important",
                                  fontFamily: "Montserrat",
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 600,
                                  lineHeight: "15px",
                                  borderColor: "transparent !important",
                                },
                                "& .MuiOutlinedInput-notchedOutline ": {
                                  border: "0px",
                                },
                                "& .MuiOutlinedInput-input": {
                                  padding: "16.5px 0px",
                                  width: "100%",
                                },
                              }}
                            ></DateTimePicker>
                          </LocalizationProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <a className="photoLabel">{t("passengers")} *</a>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              onClick={handleDecrement}
                              sx={{ p: 1 }}
                              disabled={passengers === 0}
                            >
                              <RemoveCircleIcon
                                sx={{
                                  color: "#FFF",
                                  fontSize: 20,
                                  borderRadius: "50%",
                                  backgroundColor:
                                    passengers === 0 ? "#0f111166" : "#044535",
                                }}
                              />
                            </IconButton>

                            <div
                              style={{
                                width: "30px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "0 10px",
                              }}
                            >
                              {passengers}
                            </div>

                            <IconButton onClick={handleIncrement} sx={{ p: 1 }}>
                              <AddCircleIcon
                                sx={{
                                  color: "#FFF",
                                  fontSize: 20,
                                  borderRadius: "50%",
                                  backgroundColor: "#044535",
                                }}
                              />
                            </IconButton>
                          </div>
                        </div>
                        <div className="trip-map" style={{ display: "none" }}>
                          <APIProvider
                            apiKey={"AIzaSyAju_Bdgsx_fY_VUJNTJTzKdw5P2z2A9ts"}
                          >
                            <Map
                              defaultCenter={{
                                lat:
                                  (favstation.latitude +
                                    event.station[0].latitude) /
                                  2,
                                lng:
                                  (favstation.longitude +
                                    event.station[0].longitude) /
                                  2,
                              }}
                              mapId="17c9b3c0488e85ae"
                              defaultZoom={defaultZoom}
                              style={{ borderRadius: "0.675rem !important" }}
                              gestureHandling={"greedy"}
                              disableDefaultUI={true}
                              fullscreenControl={false}
                            >
                              <Directions />
                            </Map>
                          </APIProvider>
                        </div>
                        <div
                          style={{
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <a className="photoLabel">{t("distance")}</a>
                          <TextField
                            fullWidth
                            disabled
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                backgroundColor: "white",
                                color: "#044535",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: "#044535",
                              },
                            }}
                            sx={{
                              "& .Mui-disabled": {
                                backgroundColor: "white !important",
                                color: "#044535 !important",
                              },
                            }}
                            variant="standard"
                            value={distance}
                          />
                        </div>
                        <div
                          style={{
                            marginTop: "30px",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <a className="photoLabel">{t("co2saved")}</a>
                          <TextField
                            fullWidth
                            disabled
                            InputProps={{
                              disableUnderline: true,
                              style: {
                                backgroundColor: "white",
                                color: "#044535",
                              },
                            }}
                            InputLabelProps={{
                              shrink: true,
                              style: {
                                color: "#044535",
                              },
                            }}
                            sx={{
                              "& .Mui-disabled": {
                                backgroundColor: "white !important",
                                color: "#044535 !important",
                              },
                            }}
                            variant="standard"
                            value={`${co2saved}g`}
                          />
                        </div>
                        <div
                          className="bottomRowInfoClient"
                          style={{ marginBottom: "20px !important" }}
                        >
                          <button
                            className="clientInfoEditButtonShort2"
                            onClick={() => {
                              create();
                              handleClose2();
                            }}
                          >
                            {t("addtrip")}
                          </button>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                </div>
              </div>
              <div className="eventmap">
                <APIProvider apiKey={"AIzaSyAju_Bdgsx_fY_VUJNTJTzKdw5P2z2A9ts"}>
                  <Map
                    defaultCenter={{
                      lat: Number(event.coordinates[0]),
                      lng: Number(event.coordinates[1]),
                    }}
                    mapId="17c9b3c0488e85ae"
                    defaultZoom={12}
                    style={{
                      borderRadius: "0.9375rem !important",
                    }}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                    fullscreenControl={false}
                  >
                    <AdvancedMarker
                      position={{
                        lat: Number(event.coordinates[0]),
                        lng: Number(event.coordinates[1]),
                      }}
                    >
                      <Pin
                        background={"#007BFF"}
                        borderColor={"#0056b3"}
                        glyphColor={"#003366"}
                      />
                    </AdvancedMarker>
                    {partners.map((partner, index) => {
                      const [lat, lng] = partner.coordinates;

                      return (
                        <AdvancedMarker
                          key={index}
                          position={{
                            lat: parseFloat(lat),
                            lng: parseFloat(lng),
                          }}
                          title={partner.name}
                        >
                          <Pin
                            background={"#FFD700"}
                            borderColor={"#FFC107"}
                            glyphColor={"#FFB300"}
                          />
                        </AdvancedMarker>
                      );
                    })}
                    {trainstations.map((station, index) => (
                      <AdvancedMarker
                        key={index}
                        position={{
                          lat: station.latitude,
                          lng: station.longitude,
                        }}
                        title={station.parish}
                      >
                        {" "}
                        <Pin
                          background={"#fd7000"}
                          borderColor={"#D56102"}
                          glyphColor={"#D56102"}
                        />
                      </AdvancedMarker>
                    ))}
                  </Map>
                </APIProvider>
              </div>

              <div
                style={{
                  maxWidth: "600px",
                  margin: "20px auto",
                  padding: "10px",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                <Typography variant="h5" gutterBottom>
                  {t("reviews")}
                </Typography>
                <List>
                  {reviews.map((review, index) => (
                    <ListItem
                      key={index}
                      style={{
                        borderBottom: "1px solid #ccc",
                        padding: "10px 0",
                      }}
                    >
                      <div>
                        <Rating
                          value={review.rating}
                          readOnly
                          style={{ color: "#11bb55" }}
                        />
                        <Typography
                          variant="body2"
                          style={{
                            marginTop: "5px",
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          {review.obs}
                        </Typography>
                        <Typography
                          variant="caption"
                          style={{
                            color: "gray",
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          {new Date(review.datetime).toLocaleDateString()}
                        </Typography>
                      </div>
                    </ListItem>
                  ))}
                </List>

                <Typography
                  variant="subtitle1"
                  style={{
                    marginTop: "20px",
                    marginBottom: "10px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: "bold",
                  }}
                >
                  {t("newReview")}
                </Typography>

                <div style={{ marginBottom: "20px" }}>
                  <Rating
                    value={newReview.rating}
                    onChange={(e, value) =>
                      setNewReview({ ...newReview, rating: value })
                    }
                    style={{ color: "#11bb55" }}
                  />
                  <TextField
                    label={t("commentLabel")}
                    value={newReview.obs}
                    onChange={(e) =>
                      setNewReview({ ...newReview, obs: e.target.value })
                    }
                    fullWidth
                    margin="normal"
                    multiline
                    rows={4}
                    InputProps={{
                      style: {
                        backgroundColor: "#ffffff",
                        fontFamily: "Montserrat, sans-serif",
                      },
                    }}
                    InputLabelProps={{
                      style: {
                        fontFamily: "Montserrat, sans-serif",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#ccc",
                        },
                        "&:hover fieldset": {
                          borderColor: "#ccc",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#11bb55",
                        },
                      },
                      "& .MuiInputLabel-root": {
                        color: "#ccc",
                        fontFamily: "Montserrat, sans-serif",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#11bb55",
                      },
                      "& .MuiOutlinedInput-input": {
                        backgroundColor: "#ffffff",
                        fontFamily: "Montserrat, sans-serif",
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#11bb55",
                      color: "white",
                      fontFamily: "Montserrat, sans-serif",
                    }}
                    onClick={handleSubmit}
                  >
                    {t("submit")}
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
      {loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "60px",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Skeleton
            variant="rectangular"
            width={"13.8125rem"}
            height={"2.5rem"}
            sx={{ borderRadius: "0.5rem" }}
          />
          <Skeleton
            variant="rectangular"
            width={"13.8125rem"}
            height={"2.5rem"}
            sx={{ borderRadius: "0.5rem" }}
          />
        </div>
      ) : (
        <div
          className="bottomRowInfoClient2"
          style={{ marginBottom: "20px !important", marginTop: "5px" }}
        >
          <button
            className="clientInfoEditButtonShort"
            onClick={() => {
              navigate("/ticketsclient");
            }}
          >
            {t("buytrip")}
          </button>
          <button className="clientInfoCancelButtonShort">
            {" "}
            {t("buytickets")}
          </button>
          <button
            className="clientInfoEditButtonShort"
            onClick={() => {
              handleOpen2();
            }}
          >
            {t("igotrain")}
          </button>
        </div>
      )}
    </div>
  );
}

export default EventPageClient;
