import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  MenuItem,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import HeaderClient from "../../components/headerClient";
import CloseIcon from "@mui/icons-material/Close";
import { postOrder } from "../../api/requests/Orders";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import NavClientHorizontal from "../../components/navClientHorizontal";

function HelpClient() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [obs, setObs] = useState("");
  const id = Cookies.get("id");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function create() {
    if (!obs) {
      toast.error(t("obsValidation"));
      return;
    }

    postOrder({
      user_type: "client",
      obs: obs,
      user_id: id,
    })
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("successReport"));
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    create();
    setObs("");
    setType("");
    handleClose();
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "60%" : "30%",
    borderRadius: "0.93rem",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 3,
  };

  return (
    <div className="background">
      <HeaderClient />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}
      <div className="centerpage">
  <a className="greenTitle">{t("help")}</a>
  <div className="whiteBoard">
    <div className="scrollableContent">
      <p className="helpTitle">Introdução ao Sistema</p>
      <p className="helpText">
        Bem-vindo ao nosso sistema! Aqui pode encontrar ferramentas úteis para
        acompanhar informações relacionadas com as suas viagens e preferências.
        Este guia foi criado para lhe oferecer orientações claras sobre como
        utilizar as principais funcionalidades disponíveis.
      </p>

      <p className="helpTitle">Funcionalidades Principais</p>
      <p className="helpText">
        O sistema oferece diversas funcionalidades, incluindo a Consulta de
        Viagens, onde pode obter informações detalhadas sobre as suas viagens,
        como origem, destino, poupança de CO2 e distância percorrida. Além disso,
        poderá monitorizar o seu Impacto Ambiental através de relatórios que
        mostram a poupança de CO2 com base nos meios de transporte utilizados.
        Pode também configurar as suas Preferências Personalizadas, associando
        zonas de interesse às suas preferências culturais e desportivas. Por
        fim, o sistema inclui um Mapa Interativo, que permite explorar locais de
        interesse, como estações de comboio e zonas preferidas, com a opção de
        filtrar por categorias.
      </p>

      <p className="helpTitle">Como Navegar no Sistema</p>
      <p className="helpText">
        O sistema está dividido em várias secções principais, acessíveis através
        do menu principal: Na secção Resumo, pode visualizar as principais
        informações sobre as suas atividades, incluindo estatísticas e
        notificações. A secção Viagens apresenta uma lista de todas as viagens
        realizadas, com detalhes como destino, distância e impacto ambiental. Em
        Preferências, pode configurar as suas preferências culturais e
        desportivas, selecionando as zonas de interesse para personalizar a sua
        experiência. Por último, a secção Mapa disponibiliza uma ferramenta para
        visualizar locais de interesse no mapa, incluindo áreas relacionadas com
        as suas preferências.
      </p>

      <p className="helpTitle">Dicas Úteis</p>
      <p className="helpText">
        Para garantir a melhor utilização do sistema, certifique-se de guardar
        as alterações às suas preferências para que estejam sempre atualizadas.
        Utilize os filtros disponíveis para personalizar a exibição das
        informações e, caso encontre algum problema, utilize o botão Relatar
        Problema para nos informar.
      </p>

      <p className="helpTitle">Suporte Técnico</p>
      <p className="helpText">
        Se precisar de assistência, a nossa equipa de suporte está disponível.
        Clique no botão Relatar Problema nesta página ou envie um e-mail para:{" "}
        
      </p>
      <a href="mailto:suporte@sistema.com" style={{marginBottom:"20px"}}>suporte@sistema.com</a>
    </div>
    <div id="report-issue-container">
      <button id="report-issue-button" onClick={handleOpen}>
        {t("reportProblem")}
      </button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontFamily: "Montserrat !important",
            }}
          >
            <Typography
              variant="h6"
              component="h2"
              sx={{ fontFamily: "Montserrat, sans-serif" }}
            >
              {t("reportProblem")}
            </Typography>

            <IconButton onClick={handleClose} aria-label="fechar">
              <CloseIcon />
            </IconButton>
          </div>
          <form onSubmit={handleSubmit}>
            <TextField
              label={t("obs")}
              multiline
              rows={4}
              value={obs}
              onChange={(e) => setObs(e.target.value)}
              fullWidth
              margin="normal"
              required
              sx={{
                fontFamily: "Montserrat, sans-serif",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "grey",
                  },
                  "&:hover fieldset": {
                    borderColor: "#28a745",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#28a745",
                  },
                },
                "& .MuiInputLabel-root": {
                  color: "grey",
                  "&.Mui-focused": {
                    color: "#28a745",
                  },
                },
              }}
            />

            <Button
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: "#28a745",
                fontFamily: "Montserrat, sans-serif",
                color: "white",
                "&:hover": { backgroundColor: "#218838" },
              }}
            >
              {t("submit")}
            </Button>
          </form>
        </Box>
      </Modal>
    </div>
  </div>
</div>

    </div>
  );
}

export default HelpClient;
