import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";

import i18next from "i18next";
import Header from "../../components/header";
import NavManager from "../../components/navManager";
import HeaderManager from "../../components/headerManager";
import HeaderManagerWhite from "../../components/headerManagerWhite";
import NavManagerHorizontal from "../../components/navManagerHorizontal";

function HelpManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <HeaderManagerWhite />
      {isMobile ? <NavManagerHorizontal /> : <NavManager />}

      <div className="centerpage">
        <a className="greenTitle">{t("help")}</a>
        <div className="whiteBoard">
          <div className="scrollableContent">
     
              <h2 className="helpTitle">Gestão de Utilizadores</h2>
              <p className="helpText">
                Aqui pode adicionar, editar ou remover utilizadores. Utilize o
                menu lateral para aceder às opções de gestão.
              </p>
              <p className="helpText">
                Para redefinir palavras-passe, aceda à página do perfil do
                utilizador e clique em "Redefinir Palavra-passe".
              </p>
     
            <hr />
        
              <h2 className="helpTitle">Gestão de Eventos</h2>
              <p className="helpText">
                Crie eventos com detalhes como nome, data de início e fim,
                localização e descrição. Os eventos podem ser filtrados por
                estado ou data.
              </p>
              <p className="helpText">
                Utilize a opção "Exportar Dados" para gerar relatórios em
                formato CSV ou PDF.
              </p>
     
            <hr />
           
              <h2 className="helpTitle">Relatórios</h2>
              <p className="helpText">
                Aceda a relatórios detalhados sobre atividades, incluindo
                métricas de desempenho e estatísticas em gráficos interativos.
              </p>
              <p className="helpText">
                Personalize os relatórios escolhendo intervalos de tempo e
                filtros específicos.
              </p>

            <hr />
   
              <h2 className="helpTitle">Configurações do Sistema</h2>
              <p className="helpText">
                Ajuste as configurações gerais do sistema, como idioma, fuso
                horário e permissões de acesso.
              </p>
              <p className="helpText">
                Para realizar cópias de segurança, aceda à aba "Manutenção" e
                clique em "Criar Cópia de Segurança".
              </p>

          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpManager;
