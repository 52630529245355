import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Checkbox,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { getPayments } from "../../api/requests/Payments";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { getStatus } from "../../api/requests/Status";
import i18next from "i18next";
import { getPlans } from "../../api/requests/PaymentPlans";
import { getOffers } from "../../api/requests/Offers";
import NavAdminMobile from "../../components/navAdminHorizontal";

function PartnerOffersAdmin() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/partnersadmin");
  }
  const [payments, setPayments] = useState([]);
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      navigate("/loginadmin");
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }
    getPayments()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPayments(res.data);
          setPaymentsCopy(res.data);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
      getOffers()
      .then((res) => {
        if (res.status === 200 && res.success) {

          setOffers(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getStatus()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setStatus(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const [paymentsCopy, setPaymentsCopy] = useState([]);
  const [search, setSearch] = useState("");


  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop:isMobile?"0px": "-50px",
          marginBottom: isMobile?"0px":"30px",
          marginRight: "0px",
        }}
      >
        {/* <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />  */}
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
               fontSize: "clamp(12px, 2vw, 16px)"
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayPayments = paymentsCopy;
      arrayPayments = arrayPayments.filter((element) =>
        element.owner_name.toLowerCase().includes(search.toLowerCase())
      );
      setPayments(arrayPayments);
    } else {
      setPayments(paymentsCopy);
    }
  }, [search]);


  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [columnVisibilityModel, setColumnVisibilityModel] = React.useState({
    obs: !isSmallScreen,
  });

  const handleResize = () => {
    const smallScreen = window.innerWidth < 1000;
    setIsSmallScreen(smallScreen);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    setColumnVisibilityModel({
      obs: !isSmallScreen,

    });
  }, [isSmallScreen]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <Header />
      {isMobile?<NavAdminMobile/>:<NavAdmin />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
        <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("possibleoffers")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: isMobile?15:45,
              marginLeft: isMobile?10:30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
          <ThemeProvider theme={theme}>
            <DataGrid
              className={"grid"}
              autoPageSize={true}
              columnVisibilityModel={columnVisibilityModel}
              loading={loading}
              columns={[
                // {
                //   field: "_id",
                //   headerName: t("id"),
                //   type: "number",
                //   maxWidth: 70,
                //   flex: 1,
                // },
                {
                  field: "name",
                  headerName: t("name"),
                  type: "string",
                  // headerAlign:"center",
                  // align:"center",
                  flex: 1,
                },

                  {
                    field: "obs",
                    headerName: t("obs"),
                    type: "string",
                  //   headerAlign:"center",
                  // align:"center",
                    flex: 1,
                  },
                  {
                    field: "discount",
                    headerName: t("discountpercent"),
                    type: "string",
                    headerAlign:"center",
                  align:"center",
                    flex: 1,
                  },

                  {
                    field: "actions",
                    type: "actions",
                    headerName: t("actions"),
                    flex: 1,
                    cellClassName: "actions",
                    getActions: ({ id }) => {
                      return [
                        <GridActionsCellItem
                          icon={
                            <FontAwesomeIcon
                              style={{ fontSize: 18, color: "#0F1111" }}
                              icon={faMagnifyingGlass}
                            />
                          }
                          label="Save"
                          onClick={() => {
                            // console.log(id)
                            navigate("/offerinfopage", {
                              state: { id: id },
                            });
                          }}
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                    "& .MuiDataGrid-cell": {
                      fontSize: "clamp(12px, 1.5vw, 16px)",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      fontSize: "clamp(14px, 2vw, 18px)",
                    },
                    "& .MuiDataGrid-footerContainer": {
                      fontSize: "clamp(12px, 1.5vw, 16px)",
                    },
                    "& .MuiTablePagination-root ": {
                    overflow: "hidden !important",
                  },
                }}
                rows={offers}
                checkboxSelection={true}
                onRowClick={(params) => {}}
                getRowId={(row) => row._id}
                localeText={
                  i18n.language == "pt"
                    ? ptPT.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  BaseCheckbox: ({ value, ...otherProps }) => (
                    <Checkbox size="small" checked={value} {...otherProps} />
                  ),
                  LoadingOverlay: <CircularProgress />,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="clientInfoEditButton"
              onClick={() => {
                navigate("/offercreatepage");
              }}
            >
              {t("addOffer")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerOffersAdmin;
