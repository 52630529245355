import { makeRequestDelete2, makeRequestGet, makeRequestGet2, makeRequestPost2, makeRequestPut2 } from "../api";
import Cookies from "js-cookie";

function getToken() {
  return new Promise((resolve) => {
    const checkToken = () => {
      let token = Cookies.get("token");
      if (token) {
        resolve(token);
      } else {
        setTimeout(checkToken, 100); 
      }
    };
    checkToken();
  });
}

export async function getEvaluations() {
  let token = await getToken();
  return makeRequestGet2(`evaluations/`, token);
}
export async function getEvaluation(id) {
  let token = await getToken();
  return makeRequestGet2(`evaluations/${id}`, token);
}
export async function postEvaluations(data) {
  let token = await getToken();
  return makeRequestPost2(`evaluations/`, data, token);
}
export async function updateEvaluations(id, data) {
  let token = await getToken();
  return makeRequestPut2(`evaluations/${id}`, data, token);
}
export async function putEvaluations(id, data) {
  let token = await getToken();
  return makeRequestPut2(`evaluations/${id}`, data, token);
}
export async function deleteEvaluations(id, data) {
  let token = await getToken();
  return makeRequestDelete2(`evaluations/${id}`, data, token);
}
