import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Typography, Card, CardContent, CardMedia, Button, Skeleton } from "@mui/material";
import HeaderClient from "../../components/headerClient";
import NavClientHorizontal from "../../components/navClientHorizontal";
import NavClient from "../../components/navClient";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import "../../App.css";
import { Favorite, FavoriteBorder } from "@mui/icons-material";
import feira from "../../images/example/mmachado.jpeg";
import { useTranslation } from "react-i18next";
import { getUser } from "../../api/requests/User";
import { getEvents } from "../../api/requests/Event";
import { getZones } from "../../api/requests/Data";
import locationIconSmall from "../../images/eventIcons/locationIconSmall.svg";
import calendarIconSmall from "../../images/eventIcons/calendarIconSmall.svg";

function FavoriteList() {
   const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
   const userid = Cookies.get("id");
  const [loading, setLoading] = useState(true);
  const [sportsPreference, setSportsPreference] = useState([]);
  const [culturePreference, setCulturePreference] = useState([]);
  const [zones, setZones] = useState([]);
    const [name, setName] = useState(0);
    const [search, setSearch] = useState("");
    const [events, setEvents] = useState([]);
    const [eventsCopy, setEventsCopy] = useState([]);

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");
    const nameU = Cookies.get("first_name");

    if (!id && !token) {
      navigate("/loginclient");
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const [favoriteEvents, setFavoriteEvents] = useState([]);
  useEffect(() => {
    getUser(userid)
      .then((res) => {
        if (res.status === 200 && res.success) {
          const data = res.data;
          const favorites = data.favorite_events || [];
          setFavoriteEvents(favorites);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      });
  }, [userid]);
  useEffect(() => {
    const fetchData = async () => {
      const id = Cookies.get("id");
      const token = Cookies.get("token");
      const nameU = Cookies.get("first_name");

      if (!id || !token) {
        navigate("/loginclient");
        Cookies.remove("id");
        Cookies.remove("email");
        Cookies.remove("token");
        Cookies.remove("first_name");
        Cookies.remove("last_name");
        Cookies.remove("photo");
        return;
      }

      setName(nameU);

      try {
        const zonesRes = await getZones();
        if (zonesRes.status === 200 && zonesRes.success) {
          setZones(zonesRes.data);
        } else {
          toast.error(zonesRes.error);
        }

        const userRes = await getUser(id);
        if (userRes.success && userRes.status === 200) {
          const sportsPref = userRes.data.sports_preference || [];
          const culturePref = userRes.data.culture_preference || [];
          setSportsPreference(sportsPref);
          setCulturePreference(culturePref);

          const eventsRes = await getEvents();
          if (eventsRes.status === 200 && eventsRes.success) {
            const fetchedEvents = eventsRes.data;
            // console.log("Eventos antes da ordenação:", fetchedEvents);

            const isInPreferences = (event, preferenceArray) => {
              return preferenceArray.some((pref) =>
                event.modality.includes(pref)
              );
            };

            const sortedEvents = fetchedEvents.sort((a, b) => {
              const inSportsA = isInPreferences(a, sportsPref);
              const inSportsB = isInPreferences(b, sportsPref);
              const inCultureA = isInPreferences(a, culturePref);
              const inCultureB = isInPreferences(b, culturePref);

              if (inSportsA && !inSportsB) return -1;
              if (!inSportsA && inSportsB) return 1;

              if (inCultureA && !inCultureB) return -1;
              if (!inCultureA && inCultureB) return 1;

              return 0;
            });

            // console.log("Eventos depois da ordenação:", sortedEvents);

            setEvents(sortedEvents);
          } else {
            toast.error(eventsRes.error);
          }
        } else {
          toast.error(userRes.error);
        }
      } catch (error) {
        toast.error("500");
      } 
    };

    fetchData();
  }, []);
  
  return (
    <div className="background">
    <HeaderClient />
    {isMobile ? <NavClientHorizontal /> : <NavClient />}
  
   
    <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop:isMobile?"4rem": "2rem",
          width: "100%",
          flexDirection: "column",
        }}
      >
    <a className="greenTitleClientData">{t("favoriteEvents")}</a>
    <div className="centerContent2">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 25,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
  
            <Grid container spacing={2} style={{ width: "100%" }}>
              {loading
                ? Array.from(new Array(18)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                      <Skeleton
                        variant="rectangular"
                        height="10.625rem"
                        sx={{
                          borderRadius: "0.9375rem",
                          width: { xs: "82%", sm: "22.5625rem" },
                          margin: "0 0",
                        }}
                      />
                    </Grid>
                  ))
                  : events
                  .filter((event) => favoriteEvents.includes(event._id)) 
                  .map((event) =>(
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={4}
                      key={event._id}
                    >
                      <div
                        className="event-container"
                        onClick={() =>
                          navigate("/eventpage", {
                            state: { eventId: event._id },
                          })
                        }
                      >
                        <div
                          className="event-image2"
                          style={{
                            backgroundImage: `url(${event.poster[0]})`,
                          }}
                        ></div>
                        <div className="event-details">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <div className="event-title-name">{event.name}</div>
                            <div className="event-date">
                              <div className="event-day-dark">
                                {new Date(event.start_date).getDate()}
                              </div>
                              <div className="event-month-dark">
                                {new Date(event.start_date).toLocaleString(
                                  "default",
                                  { month: "short" }
                                )}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",
                                marginTop: "1rem",
                              }}
                            >
                              <span
                                style={{
                                  width: 11,
                                  height: 16,
                                  backgroundImage: `url(${locationIconSmall})`,
                                }}
                              />
                              <div
                                className="cardContent"
                                style={{ marginLeft: 10 }}
                              >
                                {/* <a className="eventTextSmall">
                                  {event.station}
                                </a> */}
                                <a className="eventTextSmall">
                                  {event.district_name}, Portugal
                                </a>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",
                                marginTop: 10,
                              }}
                            >
                              <span
                                style={{
                                  width: 14,
                                  height: 16,
                                  backgroundImage: `url(${calendarIconSmall})`,
                                }}
                              />
                              <div
                                className="cardContent"
                                style={{ marginLeft: 10 }}
                              >
                                <a className="eventTextSmall">
                                  {new Date(
                                    event.start_date
                                  ).toLocaleDateString()}{" "}
                                  |{" "}
                                  {new Date(
                                    event.end_date
                                  ).toLocaleDateString()}
                                </a>
                              </div>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginLeft: "10px",
                                }}
                               
                              >
                                {favoriteEvents.includes(event._id) ? (
                                  <Favorite sx={{ color: "#E53935" }} />
                                ) : (
                                  <FavoriteBorder sx={{ color: "#E53935" }} />
                                )}
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
            </Grid>
            </div>
            </div>
    </div>
  </div>
  
  

  );
}

export default FavoriteList;
