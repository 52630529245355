import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Chart from "chart.js/auto";

import portugal from "../../images/example/portugalfut.jpeg";
import { Grid, Box, Skeleton } from "@mui/material";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import HeaderManagerTransparent from "../../components/headerManagerTransparent";
import NavManager from "../../components/navManager";
import StadiumBtn from "../../images/dashboardManagerIcons/stadium100.svg";
import CupBtn from "../../images/dashboardManagerIcons/cup100.svg";
import PromotersBtn from "../../images/dashboardManagerIcons/promoters100.svg";
import PartyBtn from "../../images/dashboardManagerIcons/party100.svg";
import ShopBtn from "../../images/dashboardManagerIcons/shop100.svg";
import { getEvents, getEventsGraph } from "../../api/requests/Event";
import { getActivities, getDistricts } from "../../api/requests/Data";
import { getUserChartData, getUserGrowth } from "../../api/requests/User";
import { getEquipmentsByPeriod } from "../../api/requests/Equipment";
import { getPartnersByPeriod } from "../../api/requests/Partner";
import { getCo2SavingsByPeriod } from "../../api/requests/Trips";
import NavManagerHorizontal from "../../components/navManagerHorizontal";

function DashboardManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("month");
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const chart = useRef(null);
  const chartRefReservations = useRef(null);
  const chartReservations = useRef(null);
  const chartRefco2 = useRef(null);
  const chartco2 = useRef(null);
  const chartevents = useRef(null);
  const chartRefevents = useRef(null);
  const chartstructures = useRef(null);
  const chartRefstructures = useRef(null);
  const chartpartners = useRef(null);
  const chartRefpartners = useRef(null);
  const [totalUsers, setTotalUsers] = useState(999);
  const [totalReservations, setTotalReservations] = useState(-999);
  const [scrollLeft, setScrollLeft] = useState(0);
  const scrollRef = useRef(null);
  const [events, setEvents] = useState([]);
  const [name, setName] = useState("");
  const [percentageUsers, setPercentageUsers] = useState(-50);
  const [activities, setActivities] = useState(true);
  const [districts, setDistricts] = useState(true);
  const [loading, setLoading] = useState(true);
  const userLanguage = navigator.language.split("-")[0];
  const [chartDataReady, setChartDataReady] = useState(false);

  const handleScroll = (scrollOffset) => {
    if (scrollRef.current) {
      const newScrollLeft = scrollRef.current.scrollLeft + scrollOffset;
      scrollRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
      setScrollLeft(newScrollLeft);
    }
  };

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
      navigate("/loginadmin");
      return;
    }

    const fetchData = async () => {
      try {
        const [eventsRes, districtsRes, activitiesRes] = await Promise.all([
          getEvents(),
          getDistricts(),
          getActivities(),
        ]);

        if (eventsRes.status === 200 && eventsRes.success) {
          setEvents(eventsRes.data);
        } else {
          toast.error(eventsRes.error);
        }

        if (districtsRes.status === 200 && districtsRes.success) {
          setDistricts(districtsRes.data);
        } else {
          toast.error(districtsRes.error);
        }

        if (activitiesRes.status === 200 && activitiesRes.success) {
          setActivities(activitiesRes.data);
        } else {
          toast.error(activitiesRes.error);
        }

        setChartDataReady(true);
      } catch (error) {
        toast.error(t("500"));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    getUserGrowth({ period: selectedOption })
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTotalUsers(res.data.difference);
          setPercentageUsers(res.data.percentageChange);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, [selectedOption]);

  useEffect(() => {
    const fetchDataAndRenderChart = async () => {
      if (!chartRef.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getUserChartData({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          if (chartInstance.current) {
            chartInstance.current.destroy();
          }

          const ctx = chartRef.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartInstance.current = new Chart(ctx, {
            type: "line",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "User Growth",
                  data: data,
                  borderColor: "white",
                  tension: 0.4,
                  borderCapStyle: "round",
                  pointHoverRadius: 0,
                  pointRadius: 0,
                  fill: {
                    target: "origin",
                    above: "rgba(255, 255, 255, 0.5)",
                  },
                },
              ],
            },
            options: {
              scales: {
                x: { display: false },
                y: { display: false },
              },
              plugins: {
                legend: { display: false },
              },
              responsive: true,
              maintainAspectRatio: false,
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro de rede:", error);
      }
    };

    if (chartDataReady) {
      fetchDataAndRenderChart();
    }
  }, [chartDataReady, selectedOption]);

  useEffect(() => {
    if (!loading) {
      const ctx = chartRefReservations.current.getContext("2d");

      if (chartReservations.current !== null) {
        chartReservations.current.destroy();
      }

      const redGradient = ctx.createLinearGradient(0, 0, 0, 90);
      redGradient.addColorStop(0, "#ED4F2C");
      redGradient.addColorStop(1, "rgba(255,0,0,0)");

      const greenGradient = ctx.createLinearGradient(0, 0, 0, 90);
      greenGradient.addColorStop(0, "#13BB56");
      greenGradient.addColorStop(1, "rgba(19, 187, 86, 0)");

      chartReservations.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets: [
            {
              label: "My Second Dataset",
              data: [40, 55, 56, 81, 80, 59, 65],
              borderColor: totalReservations < 0 ? "#ED4F2C" : "#13BB56",
              tension: 0.4,
              borderCapStyle: "round",
              pointHoverRadius: 0,
              pointRadius: 0,
              fill: {
                target: "origin",
                above: totalReservations < 0 ? redGradient : greenGradient,
              },
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }, [loading]);

  useEffect(() => {
    const co2Graph = async () => {
      if (!chartRefco2.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getCo2SavingsByPeriod({ period: selectedOption });

        if (res && res.success) {
          const { labels, data } = res.data;

          if (chartco2.current) {
            chartco2.current.destroy();
          }

          const ctx = chartRefco2.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartco2.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: t("co2saved"),
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      co2Graph();
    }
  }, [loading, selectedOption]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <HeaderManagerTransparent />
      {isMobile ? <NavManagerHorizontal /> : <NavManager />}
      <div className="backgroundImageDashboardManager">
        <div className="leftcenter">
          <a className="titleDashboardClient">{name}</a>
          <a className="subtitleDashboardClient">Viana do Castelo</a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "6.25rem",
            paddingTop: "12vh",
          }}
        >
          <button
            className="btnDashboardManager"
            style={{ backgroundImage: `url(${PromotersBtn})` }}
            onClick={() => {
              navigate("/promotersmanager");
            }}
          ></button>

          <button
            className="btnDashboardManager"
            style={{ backgroundImage: `url(${PartyBtn})` }}
            onClick={() => {
              navigate("/eventsmanager");
            }}
          ></button>
          <button
            className="btnDashboardManager"
            style={{ backgroundImage: `url(${StadiumBtn})` }}
            onClick={() => {
              navigate("/equipmentsmanager");
            }}
          ></button>
          <button
            className="btnDashboardManager"
            style={{ backgroundImage: `url(${ShopBtn})` }}
            onClick={() => {
              navigate("/partnersmanager");
            }}
          ></button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position:"absolute",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
            height: isMobile? "60vh":"100vh",
            marginTop:isMobile?"24vh":"25vh",
            overflowY: "auto", 
            overflowX: "hidden", 
          }}
        >
          <div className="toprow2">
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={"19.6875rem"}
                height={"11rem"}
                sx={{ marginInline: "1.25rem", borderRadius: "15px" }}
              />
            ) : totalUsers >= 0 ? (
              <div className="greenCard">
                <div className="graphTopRow">
                  <a className="whiteTextGraph">{t("totalusers")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthGrey"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "5.225rem",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: 10,
                  }}
                >
                  <canvas ref={chartRef} />
                </div>
                <div className="graphBottomRow">
                  <a className="whiteValue">{totalUsers}</a>
                  <div className="percentageGraphWhite">
                    <a className="arrowTextGreen">↑</a>
                    <a className="percentageTextGreen"> {percentageUsers}%</a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="redCard">
                <div className="graphTopRow">
                  <a className="whiteTextGraph">{t("totalusers")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthGrey"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "5.225rem",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: 10,
                  }}
                >
                  <canvas ref={chartRef} />
                </div>
                <div className="graphBottomRow">
                  <a className="whiteValue">{totalUsers}</a>
                  <div className="percentageGraphWhite">
                    <a className="arrowTextRed">↓</a>
                    <a className="percentageTextRed"> {percentageUsers}%</a>
                  </div>
                </div>
              </div>
            )}
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={"19.6875rem"}
                height={"11rem"}
                sx={{ marginInline: "1.25rem", borderRadius: "15px" }}
              />
            ) : totalReservations >= 0 ? (
              <div className="whiteCard">
                <div className="graphTopRow">
                  <a className="blackTextGraph">{t("totalreservations")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthBlack"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "5.225rem",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: 10,
                  }}
                >
                  <canvas ref={chartRefReservations} />
                </div>
                <div className="graphBottomRow">
                  <a className="blackValue">{totalReservations}</a>
                  <div className="percentageGraphGreen">
                    <a className="arrowTextWhite">↑</a>
                    <a className="percentageTextWhite"> 32%</a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="whiteCard">
                <div className="graphTopRow">
                  <a className="blackTextGraph">{t("totalreservations")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthBlack"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "5.225rem",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: 10,
                  }}
                >
                  <canvas ref={chartRefReservations} />
                </div>
                <div className="graphBottomRow">
                  <a className="blackValue">{totalReservations}</a>
                  <div className="percentageGraphRed">
                    <a className="arrowTextWhite">↓</a>
                    <a className="percentageTextWhite"> 29.5%</a>
                  </div>
                </div>
              </div>
            )}
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={"11rem"}
                sx={{
                  width: "57%",
                  maxWidth: "44%",
                  boxSizing: "border-box",
                  borderRadius: "0.9375rem",
                }}
              />
            ) : (
              <div className="whiteBigCard2">
                <div className="graphTopRow">
                  <a className="blackTextGraph">{t("co2savings")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthBlack"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "8.25rem",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <canvas ref={chartRefco2} />
                </div>
              </div>
            )}
          </div>

          <div className="dashboardManagerEvents">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width:isMobile?"100%": "50%",
                marginRight: isMobile?0: 10,
              }}
            >
              <p className="blackTextTitleDashboard">{t("infoEvents")}</p>
              <div className="scroll-container">
                <div className="card-wrapper" ref={scrollRef}>
                  {loading
                    ? Array.from(new Array(20)).map((_, index) => (
                        <div className="card" key={index}>
                          <Skeleton
                            variant="rectangular"
                            height="13.125rem"
                            width="9.375rem"
                            sx={{ borderRadius: "0.9375rem" }}
                          />
                        </div>
                      ))
                    : events.map((card, index) => (
                        <div className="event-card" key={index}>
                          <div
                            className="event-image"
                            style={{
                              backgroundImage: `url(${card.poster[0]})`,
                            }}
                          ></div>
                          <div className="event-details">
                            <div className="event-title">{card.name}</div>
                            <div>
                              <div className="event-text">
                                {t("visitsTotal")}: <strong>100</strong>
                              </div>
                              <div className="event-text">
                                {t("outDistrict")}: <strong>40</strong>
                              </div>
                              <div className="event-text">
                                {t("otherServices")}: <strong>10</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                </div>
                {/* <button className="scroll-arrow left-arrow" onClick={() => handleScroll(-100)}>
    ←
  </button>
  <button className="scroll-arrow right-arrow" onClick={() => handleScroll(100)}>
    →
  </button> */}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: isMobile?"100%":"50%",
                justifyContent: "flex-start",
                marginleft: isMobile?0: 10,
              }}
            >
              <p className="blackTextTitleDashboard">{t("next")}</p>
              <div className="scroll-container">
                <div className="cards" ref={scrollRef}>
                  {loading
                    ? Array.from(new Array(20)).map((_, index) => (
                        <div className="card" key={index}>
                          <Skeleton
                            variant="rectangular"
                            height="13.125rem"
                            width="9.375rem"
                            sx={{ borderRadius: "0.9375rem" }}
                          />
                        </div>
                      ))
                    : events.map((card, index) => {
                        const eventDate = new Date(card.start_date);

                        // Formatação da data
                        const day = eventDate.toLocaleDateString("en-US", {
                          day: "numeric",
                        });
                        const month = eventDate.toLocaleDateString("en-US", {
                          month: "short",
                        });

                        return (
                          <div
                            className="card"
                            key={index}
                            style={{
                              backgroundImage: `url(${card.poster[0]})`,
                            }}
                            onClick={() => {
                              navigate("/eventpagemanager", {
                                state: { eventId: card.id },
                              });
                            }}
                          >
                            <div className="darkEnd">
                              <div className="event-info">
                                <div className="event-date">
                                  <div className="event-day">{day}</div>
                                  <div className="event-month">{month}</div>
                                </div>
                                <div className="event-name">{card.name}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
                {/* <button
                  className="scroll-button left"
                  onClick={() => handleScroll(-100)}
                >
                  ←
                </button>
                <button
                  className="scroll-button right"
                  onClick={() => handleScroll(100)}
                >
                  →
                </button> */}
              </div>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
            }}
          >
            <button
              className="clientInfoEditButton"
              onClick={() => {
                navigate("/eventcreatemanager");
              }}
            >
              {t("createEvent")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardManager;
