import React, { useEffect, useState, useRef } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  Skeleton,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import { getEvents, getEventsGraph } from "../../api/requests/Event";
import {
  getActivities,
  getDistricts,
  getTrainStations,
} from "../../api/requests/Data";
import { getUserChartData, getUserGrowth } from "../../api/requests/User";
import {
  getEquipments,
  getEquipmentsByPeriod,
} from "../../api/requests/Equipment";
import { getPartners, getPartnersByPeriod } from "../../api/requests/Partner";
import { getCo2SavingsByPeriod } from "../../api/requests/Trips";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import Chart from "chart.js/auto";
import {
  AdvancedMarker,
  APIProvider,
  Map,
  Pin,
} from "@vis.gl/react-google-maps";
import NavAdminMobile from "../../components/navAdminHorizontal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function StatsAdmin() {
  const [showEvents, setShowEvents] = useState(true);
  const [showPartners, setShowPartners] = useState(true);
  const [showEquipments, setShowEquipments] = useState(true);
  const [showTrainstations, setShowTrainstations] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState("month");
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [chartData, setChartData] = useState(null);
  const chart = useRef(null);
  const chartRefReservations = useRef(null);
  const chartReservations = useRef(null);
  const chartRefco2 = useRef(null);
  const chartco2 = useRef(null);
  const chartevents = useRef(null);
  const chartRefevents = useRef(null);
  const chartstructures = useRef(null);
  const chartRefstructures = useRef(null);
  const chartpartners = useRef(null);
  const chartRefpartners = useRef(null);
  const [events, setEvents] = useState(true);
  const [activities, setActivities] = useState(true);
  const [districts, setDistricts] = useState(true);
  const [loading, setLoading] = useState(true);
  const userLanguage = navigator.language.split("-")[0];
  const [chartDataReady, setChartDataReady] = useState(false);
  const [totalUsers, setTotalUsers] = useState(-9999);
  const [percentageUsers, setPercentageUsers] = useState(-50);
  const [totalReservations, setTotalReservations] = useState(999);
  const [trainstations, setTrainstations] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [partners, setPartners] = useState([]);
  const [eventsMap, setEventsMaps] = useState("");
  const [selectedDistricts, setSelectedDistricts] = useState([]);

  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  const parseCoordinates = (coordinates) => {
    if (!coordinates || typeof coordinates !== "string") return null; // Verifica se é uma string
    const [lat, long] = coordinates.split(",");
    if (!lat || !long) return null; // Verifica se latitude e longitude estão presentes
    return { lat: parseFloat(lat), lng: parseFloat(long) };
  };

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    if (!id && !token) {
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
      navigate("/loginadmin");
      return;
    }

    const fetchData = async () => {
      try {
        const [eventsRes, districtsRes, activitiesRes] = await Promise.all([
          getEvents(),
          getDistricts(),
          getActivities(),
        ]);

        if (eventsRes.status === 200 && eventsRes.success) {
          setEvents(eventsRes.data);
        } else {
          toast.error(eventsRes.error);
        }

        if (districtsRes.status === 200 && districtsRes.success) {
          setDistricts(districtsRes.data);
        } else {
          toast.error(districtsRes.error);
        }

        if (activitiesRes.status === 200 && activitiesRes.success) {
          setActivities(activitiesRes.data);
        } else {
          toast.error(activitiesRes.error);
        }

        setChartDataReady(true);
      } catch (error) {
        toast.error(t("500"));
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate]);

  useEffect(() => {
    const fetchDataAndRenderChart = async () => {
      if (!chartRef.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getUserChartData({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          // Se já existe um gráfico, destrói antes de criar um novo
          if (chartInstance.current) {
            chartInstance.current.destroy();
          }

          const ctx = chartRef.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartInstance.current = new Chart(ctx, {
            type: "line",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "User Growth",
                  data: data,
                  borderColor: "white",
                  tension: 0.4,
                  borderCapStyle: "round",
                  pointHoverRadius: 0,
                  pointRadius: 0,
                  fill: {
                    target: "origin",
                    above: "rgba(255, 255, 255, 0.5)",
                  },
                },
              ],
            },
            options: {
              scales: {
                x: { display: false },
                y: { display: false },
              },
              plugins: {
                legend: { display: false },
              },
              responsive: true,
              maintainAspectRatio: false,
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro de rede:", error);
      }
    };

    if (chartDataReady) {
      fetchDataAndRenderChart();
    }
  }, [chartDataReady, selectedOption]);

  useEffect(() => {
    if (!loading) {
      const ctx = chartRefReservations.current.getContext("2d");

      if (chartReservations.current !== null) {
        chartReservations.current.destroy();
      }

      const redGradient = ctx.createLinearGradient(0, 0, 0, 90);
      redGradient.addColorStop(0, "#ED4F2C");
      redGradient.addColorStop(1, "rgba(255,0,0,0)");

      const greenGradient = ctx.createLinearGradient(0, 0, 0, 90);
      greenGradient.addColorStop(0, "#13BB56");
      greenGradient.addColorStop(1, "rgba(19, 187, 86, 0)");

      chartReservations.current = new Chart(ctx, {
        type: "line",
        data: {
          labels: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
          ],
          datasets: [
            {
              label: "My Second Dataset",
              data: [40, 55, 56, 81, 80, 59, 65],
              borderColor: totalReservations < 0 ? "#ED4F2C" : "#13BB56",
              tension: 0.4,
              borderCapStyle: "round",
              pointHoverRadius: 0,
              pointRadius: 0,
              fill: {
                target: "origin",
                above: totalReservations < 0 ? redGradient : greenGradient,
              },
            },
          ],
        },
        options: {
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
        },
      });
    }
  }, [loading]);

  useEffect(() => {
    const co2Graph = async () => {
      if (!chartRefco2.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getCo2SavingsByPeriod({ period: selectedOption });

        if (res && res.success) {
          const { labels, data } = res.data;

          if (chartco2.current) {
            chartco2.current.destroy();
          }

          const ctx = chartRefco2.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartco2.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "CO2 Poupado (g)",
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      co2Graph();
    }
  }, [loading, selectedOption]);

  useEffect(() => {
    const eventGraph = async () => {
      if (!chartRefevents.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getEventsGraph({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          if (chartevents.current) {
            chartevents.current.destroy();
          }

          const ctx = chartRefevents.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartevents.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Eventos",
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      eventGraph();
    }
  }, [loading, selectedOption]);

  useEffect(() => {
    const equipmentsGraph = async () => {
      if (!chartRefstructures.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getEquipmentsByPeriod({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          if (chartstructures.current) {
            chartstructures.current.destroy();
          }

          const ctx = chartRefstructures.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartstructures.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Equipaments",
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      equipmentsGraph();
    }
  }, [loading, selectedOption]);

  useEffect(() => {
    const partnersGraph = async () => {
      if (!chartRefpartners.current) {
        console.error("O canvas ainda não está disponível.");
        return;
      }

      try {
        const res = await getPartnersByPeriod({ period: selectedOption });

        if (res.status === 200 && res.success) {
          const { labels, data } = res.data;

          if (chartpartners.current) {
            chartpartners.current.destroy();
          }

          const ctx = chartRefpartners.current.getContext("2d");
          if (!ctx) {
            console.error("Erro ao obter o contexto 2D do canvas.");
            return;
          }

          chartpartners.current = new Chart(ctx, {
            type: "bar",
            data: {
              labels: labels,
              datasets: [
                {
                  label: "Partners",
                  data: data,
                  backgroundColor: "#13BB56",
                  borderWidth: 0,
                  barThickness: 15,
                  borderRadius: 4,
                },
              ],
            },
            options: {
              scales: {
                x: {
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
                y: {
                  beginAtZero: true,
                  display: false,
                  grid: {
                    drawBorder: false,
                    lineWidth: 0,
                  },
                  border: {
                    display: false,
                  },
                },
              },
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: {
                padding: {
                  bottom: 15,
                },
              },
            },
          });
        } else {
          console.error("Erro ao obter dados do gráfico:", res.error);
        }
      } catch (error) {
        console.log(error);
        console.error("Erro ao obter dados do gráfico:", error);
      }
    };

    if (!loading) {
      partnersGraph();
    }
  }, [loading, selectedOption]);

  useEffect(() => {
    getUserGrowth({ period: selectedOption })
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTotalUsers(res.data.difference);
          setPercentageUsers(res.data.percentageChange);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, [selectedOption]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          trainStationsRes,
          eventsRes,
          districtsRes,
          equipmentsRes,
          partnersRes,
        ] = await Promise.all([
          getTrainStations(),
          getEvents(),
          getDistricts(),
          getEquipments(),
          getPartners(),
        ]);

        if (trainStationsRes.status === 200 && trainStationsRes.success) {
          setTrainstations(trainStationsRes.data);
        } else {
          toast.error(trainStationsRes.error);
        }

        if (eventsRes.status === 200 && eventsRes.success) {
          setEventsMaps(eventsRes.data);
        } else {
          toast.error(eventsRes.error);
        }

        if (districtsRes.status === 200 && districtsRes.success) {
          setDistricts(
            Array.isArray(districtsRes.data) ? districtsRes.data : []
          );
        } else {
          toast.error(districtsRes.error);
        }

        if (equipmentsRes.status === 200 && equipmentsRes.success) {
          setEquipments(equipmentsRes.data);
        } else {
          toast.error(equipmentsRes.error);
        }

        if (partnersRes.status === 200 && partnersRes.success) {
          setPartners(partnersRes.data);
        } else {
          toast.error(partnersRes.error);
        }

        setLoading(false); // Apenas define `setLoading(false)` depois que todas as requisições terminarem
      } catch (error) {
        toast.error(t("500"));
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [filteredEquipments, setFilteredEquipments] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filterEventsByDistrict = (districts) => {
    if (districts.length === 0) {
      setFilteredEvents(events);
    } else {
      const filtered = events.filter((event) =>
        districts.some((district) => event.district_id == district.id)
      );
      setFilteredEvents(filtered);
    }
  };
  const filterEquipmentsByDistrict = (districts) => {
    if (districts.length === 0) {
      setFilteredEquipments(equipments);
    } else {
      const filtered = equipments.filter((equipment) =>
        districts.some((district) => equipment.district_id == district.id)
      );
      setFilteredEquipments(filtered);
    }
  };

  const filterPartnersByDistrict = (districts) => {
    if (districts.length === 0) {
      setFilteredPartners(partners);
    } else {
      const filtered = partners.filter((partner) =>
        districts.some((district) => Number(partner.district_id) == district.id)
      );
      setFilteredPartners(filtered);
    }
  };

  const handleDistrictChange = (districtId) => {
    setSelectedDistricts((prevSelectedDistricts) => {
      if (prevSelectedDistricts.includes(districtId)) {
        return prevSelectedDistricts.filter((id) => id !== districtId);
      } else {
        return [...prevSelectedDistricts, districtId];
      }
    });
  };

  useEffect(() => {
    if (selectedDistricts.length > 0) {
      filterEventsByDistrict(selectedDistricts);
      filterPartnersByDistrict(selectedDistricts);
      filterEquipmentsByDistrict(selectedDistricts);
    } else {
      setFilteredEvents(events);
      setFilteredPartners(partners);
      setFilteredEquipments(equipments);
    }
  }, [selectedDistricts, events, partners, equipments]);

  return (
    <div className="background">
      <Header />
      {isMobile ? <NavAdminMobile /> : <NavAdmin />}
      <div className="centerGraph">
        <Box sx={{ width: "100%", height: "95vh", overflowY: "auto" }}>
          <div className="rowstats">
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={"19.6875rem"}
                height={"11rem"}
                sx={{ marginInline: "1.25rem", borderRadius: "15px" }}
              />
            ) : totalUsers >= 0 ? (
              <div
                className="greenCard"
                onClick={() => navigate("/clientsadmin")}
                style={{ cursor: "pointer" }}
              >
                <div className="graphTopRow">
                  <a className="whiteTextGraph">{t("totalusers")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthGrey"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "5.225rem",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: 10,
                  }}
                >
                  <canvas ref={chartRef} />
                </div>
                <div className="graphBottomRow">
                  <a className="whiteValue">{totalUsers}</a>
                  <div className="percentageGraphWhite">
                    <a className="arrowTextGreen">↑</a>
                    <a className="percentageTextGreen"> {percentageUsers}%</a>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="redCard"
                onClick={() => navigate("/clientsadmin")}
                style={{ cursor: "pointer" }}
              >
                <div className="graphTopRow">
                  <a className="whiteTextGraph">{t("totalusers")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthGrey"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "5.225rem",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: 10,
                  }}
                >
                  <canvas ref={chartRef} />
                </div>
                <div className="graphBottomRow">
                  <a className="whiteValue">{totalUsers}</a>
                  <div className="percentageGraphWhite">
                    <a className="arrowTextRed">↓</a>
                    <a className="percentageTextRed"> {percentageUsers}%</a>
                  </div>
                </div>
              </div>
            )}
            {loading ? (
              <Skeleton
                variant="rectangular"
                width={"19.6875rem"}
                height={"11rem"}
                sx={{ marginInline: "1.25rem", borderRadius: "15px" }}
              />
            ) : totalReservations >= 0 ? (
              <div className="whiteCard">
                <div className="graphTopRow">
                  <a className="blackTextGraph">{t("totalreservations")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthBlack"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "5.225rem",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: 10,
                  }}
                >
                  <canvas ref={chartRefReservations} />
                </div>
                <div className="graphBottomRow">
                  <a className="blackValue">{totalReservations}</a>
                  <div className="percentageGraphGreen">
                    <a className="arrowTextWhite">↑</a>
                    <a className="percentageTextWhite"> 32%</a>
                  </div>
                </div>
              </div>
            ) : (
              <div className="whiteCard">
                <div className="graphTopRow">
                  <a className="blackTextGraph">{t("totalreservations")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthBlack"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "5.225rem",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingInline: 10,
                  }}
                >
                  <canvas ref={chartRefReservations} />
                </div>
                <div className="graphBottomRow">
                  <a className="blackValue">{totalReservations}</a>
                  <div className="percentageGraphRed">
                    <a className="arrowTextWhite">↓</a>
                    <a className="percentageTextWhite"> 29.5%</a>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="rowstats2">
            {loading ? (
              <Skeleton
                variant="rectangular"
                height={"11rem"}
                sx={{
                  width: "57%",
                  maxWidth: "44%",
                  boxSizing: "border-box",
                  borderRadius: "0.9375rem",
                }}
              />
            ) : (
              <div className="whiteStatsCard">
                <div className="graphTopRow">
                  <a className="blackTextGraph">{t("co2savings")}</a>
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthBlack"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                </div>
                <div
                  style={{
                    height: "8.25rem",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <canvas ref={chartRefco2} />
                </div>
              </div>
            )}
          </div>
          <div className="rowstats2">
            {loading ? (
              <div>
                <Skeleton
                  variant="rectangular"
                  width="65.75rem"
                  height="16.5rem"
                  sx={{
                    borderRadius: "0.9375rem",
                    backgroundColor: "#cfdcd2",
                  }}
                />
              </div>
            ) : (
              <div className="whiteHorizontalCard">
                <div style={{ flexDirection: "column", padding: 10 }}>
                  <div className="graphRowStats">
                    <a className="blackTextGraph">{t("numberevents")}</a>
                  </div>
                  <div
                    onClick={() => navigate("/eventsadmin")}
                    style={{
                      height: "180px",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      marginTop: 30,
                      cursor: "pointer",
                    }}
                  >
                    <canvas ref={chartRefevents} />
                  </div>
                </div>
                <div style={{ flexDirection: "column", padding: 10 }}>
                  <div className="graphRowStats">
                    <a className="blackTextGraph">{t("numberstructures")} </a>
                  </div>
                  <div
                    onClick={() => navigate("/equipmentsadmin")}
                    style={{
                      height: "180px",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      marginTop: 30,
                      cursor: "pointer",
                    }}
                  >
                    <canvas ref={chartRefstructures} />
                  </div>
                </div>
                <div style={{ flexDirection: "column", padding: 10 }}>
                  <div className="graphRowStats">
                    <a className="blackTextGraph">{t("numberpartners")}</a>
                  </div>

                  <div
                    onClick={() => navigate("/partnersadmin")}
                    style={{
                      height: "180px",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 10,
                      marginTop: 30,
                      cursor: "pointer",
                    }}
                  >
                    <canvas ref={chartRefpartners} />
                  </div>
                </div>
                {isMobile ? null : (
                  <select
                    value={selectedOption}
                    onChange={(e) => setSelectedOption(e.target.value)}
                    className="selectMonthBlackHorizontal"
                  >
                    <option value="month">{t("month")}</option>
                    <option value="year">{t("year")}</option>
                  </select>
                )}
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: isMobile ? "5px" : "20px",
              paddingTop: "20px",
              paddingBottom: "20px",
              backgroundColor: "#f8f8f8",
              width: isMobile ? "89vw" : "fit-content",
              paddingLeft: isMobile ? "5px" : "40px",
              paddingRight: isMobile ? "5px" : "40px",
              borderRadius: "10px",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
              marginTop: "30px",
              marginBottom: "30px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: isMobile ? "5px" : "10px",
                fontSize: isMobile ? "10px" : "14px",
                color: "#333",
                cursor: "pointer",
                fontFamily: "Montserrat",
              }}
            >
              <input
                type="checkbox"
                checked={showTrainstations}
                onChange={() => setShowTrainstations(!showTrainstations)}
                style={{
                  width: "18px",
                  height: "18px",
                  accentColor: "#fd6f00",
                }}
              />
              {t("showStations")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: isMobile ? "5px" : "10px",
                fontSize: isMobile ? "10px" : "14px",
                color: "#333",
                cursor: "pointer",
                fontFamily: "Montserrat",
              }}
            >
              <input
                type="checkbox"
                checked={showEvents}
                onChange={() => setShowEvents(!showEvents)}
                style={{
                  width: "18px",
                  height: "18px",
                  accentColor: "#017bfe",
                }}
              />
              {t("showEvents")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: isMobile ? "5px" : "10px",
                fontSize: isMobile ? "10px" : "14px",
                color: "#333",
                cursor: "pointer",
                fontFamily: "Montserrat",
              }}
            >
              <input
                type="checkbox"
                checked={showPartners}
                onChange={() => setShowPartners(!showPartners)}
                style={{
                  width: "18px",
                  height: "18px",
                  accentColor: "#ffd601",
                }}
              />
              {t("showPartners")}
            </label>
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: isMobile ? "5px" : "10px",
                fontSize: isMobile ? "10px" : "14px",
                color: "#333",
                cursor: "pointer",
                fontFamily: "Montserrat",
              }}
            >
              <input
                type="checkbox"
                checked={showEquipments}
                onChange={() => setShowEquipments(!showEquipments)}
                style={{
                  width: "18px",
                  height: "18px",
                  accentColor: "#ea4335",
                }}
              />
              {t("showEquipments")}
            </label>
          </div>

          <div
            style={{
              width: "75%", // Define a largura da div externa
              margin: "0 auto", // Centraliza a div horizontalmente
              marginBottom: 20,
            }}
          >
            <Accordion
              expanded={expanded}
              onChange={handleAccordionChange}
              sx={{
                boxShadow: "none", // Remover sombra do Accordion
                border: "none", // Remover bordas
                backgroundColor: "#fff", // Fundo branco para o Accordion
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "#333",
                    fontFamily: "Montserrat",
                  }}
                >
                  {t("filterDistricts")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Grid e Cards dos Distritos */}
                <Grid container spacing={2} sx={{ marginBottom: 1 }}>
                  {Array.isArray(districts) && districts.length > 0 ? (
                    districts.map((district) => (
                      <Grid item xs={4} sm={2} md={3} lg={2} key={district.id}>
                        <Card
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            margin: 1,
                            backgroundColor: selectedDistricts.includes(
                              district.id
                            )
                              ? "#c8e6c9"
                              : "#f5f5f5",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            borderRadius: "6px",
                            width: "100%",

                            color: "#333",
                            cursor: "pointer",

                            maxHeight: "100px", // Limitar a altura do cartão
                            overflow: "hidden", // Evitar que o conteúdo ultrapasse o limite
                            transition: "transform 0.2s, box-shadow 0.2s",
                            "&:hover": {
                              transform: "scale(1.03)",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                            },
                          }}
                        >
                          <CardContent
                            sx={{
                              textAlign: "center",
                              // padding: "8px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              fontFamily: "Montserrat",
                              height: "100%", // Garante que ocupa a altura total do Card
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedDistricts.includes(
                                    district.id
                                  )}
                                  onChange={() =>
                                    handleDistrictChange(district.id)
                                  }
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: 12, // Reduzir o tamanho do ícone
                                    },
                                  }}
                                />
                              }
                              label={
                                <Typography
                                  sx={{
                                    fontSize: { xs: "8px", sm: "10px" }, // Fonte menor no mobile
                                    fontFamily: "Montserrat !important",
                                    fontWeight: "500",
                                    whiteSpace: "nowrap", // Impede quebra de linha
                                    overflow: "hidden", // Esconde o texto extra
                                    textOverflow: "ellipsis", // Adiciona "..."
                                    display: "block", // Garante que funcione corretamente
                                    maxWidth: "80px", // Define um limite de largura para garantir que o texto possa ser truncado
                                    textAlign: "center",
                                  }}
                                >
                                  {district.pt}
                                </Typography>
                              }
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  ) : (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: "10px" }}
                      >
                        {t("noDistricts")}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="mapStyleStats">
              {loading ? (
                <Skeleton
                  variant="rectangular"
                  height="100%"
                  width="100%"
                  sx={{ borderRadius: "0.9375rem" }}
                />
              ) : (
                <APIProvider apiKey={"AIzaSyAju_Bdgsx_fY_VUJNTJTzKdw5P2z2A9ts"}>
                  <Map
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    mapId="17c9b3c0488e85ae"
                    defaultCenter={{
                      lat: 41.70311203750535,
                      lng: -8.828393507213907,
                    }}
                    defaultZoom={8}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                  >
                    {showTrainstations &&
                      trainstations.map((station, index) => (
                        <AdvancedMarker
                          key={index}
                          position={{
                            lat: station.latitude,
                            lng: station.longitude,
                          }}
                          title={station.parish}
                        >
                          {" "}
                          <Pin
                            background={"#fd7000"}
                            borderColor={"#D56102"}
                            glyphColor={"#D56102"}
                          />
                        </AdvancedMarker>
                      ))}

                    {showPartners &&
                      filteredPartners.map((partner, index) => {
                        const [lat, lng] = partner.coordinates;

                        return (
                          <AdvancedMarker
                            key={index}
                            position={{
                              lat: parseFloat(lat),
                              lng: parseFloat(lng),
                            }}
                            title={partner.name}
                          >
                            <Pin
                              background={"#FFD700"}
                              borderColor={"#FFC107"}
                              glyphColor={"#FFB300"}
                            />
                          </AdvancedMarker>
                        );
                      })}

                    {showEquipments &&
                      filteredEquipments.map((equipment) => {
                        const coords = parseCoordinates(equipment.coordinates);
                        return coords ? (
                          <AdvancedMarker
                            key={equipment.id}
                            position={coords}
                            label={equipment.name}
                          />
                        ) : null;
                      })}
                    {showEvents &&
                    Array.isArray(filteredEvents) &&
                    filteredEvents.length > 0 ? (
                      filteredEvents.map((event) => {
                        const [lat, lng] = event.coordinates; // Desestruturação das coordenadas

                        return (
                          <AdvancedMarker
                            key={event.id} // Usando o ID do evento como chave para melhor desempenho
                            position={{
                              lat: parseFloat(lat), // Convertendo a latitude para float
                              lng: parseFloat(lng), // Convertendo a longitude para float
                            }}
                            title={event.name}
                          >
                            <Pin
                              background="#007BFF" // Definindo o fundo do pin
                              borderColor="#0056b3" // Cor da borda do pin
                              glyphColor="#003366" // Cor do ícone do pin
                            />
                          </AdvancedMarker>
                        );
                      })
                    ) : (
                      <p>No events to display</p> // Mensagem caso filteredEvents não seja um array ou esteja vazio
                    )}
                  </Map>
                </APIProvider>
              )}
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}

export default StatsAdmin;
