import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import { Grid, Box, Typography, Skeleton } from "@mui/material";
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import EventsBtn from "../../images/clientDashboardIcons/EventsClear.svg";
import StationsBtn from "../../images/clientDashboardIcons/StationsClear.svg";
import PartnersBtn from "../../images/clientDashboardIcons/PartnersBtn.svg";
import bag from "../../images/clientDashboardIcons/bag.svg";
import portugal from "../../images/example/portugalfut.jpeg";
import feira from "../../images/example/feira.jpeg";
import hoquei from "../../images/example/hoquei.jpeg";
import amalia from "../../images/example/amalia.jpeg";
import viana from "../../images/example/viana.png";
import locationIconSmall from "../../images/eventIcons/locationIconSmall.svg";
import phoneIcon from "../../images/eventIcons/phoneIcon.svg";
import phoneIconSmall from "../../images/eventIcons/phoneIconSmall.svg";
import calendarIconSmall from "../../images/eventIcons/calendarIconSmall.svg";
import { createRoot } from "react-dom/client";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import HeaderClientTransparent from "../../components/headerClientTransparent";
import Search1 from "../../components/Search1";
import Cookies from "js-cookie";
import { getPartners } from "../../api/requests/Partner";
import toast from "react-hot-toast";
import NavClientHorizontal from "../../components/navClientHorizontal";
import { getUser } from "../../api/requests/User";
import { Favorite, FavoriteBorder, Star, StarBorder } from "@mui/icons-material";

function PartnersList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState(0);
  const [loading, setLoading] = useState(true);
  const [partners, setPartners] = useState([]);
  const userid = Cookies.get("id");

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");
    const nameU = Cookies.get("first_name");
    setName(nameU);

    //If don't have an ID or token
    if (!id && !token) {
      navigate("/loginclient");
      //Remove the data and forward to the Login page
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }

    getPartners()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPartners(res.data);

          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const [search, setSearch] = useState("");

  const [partnersCopy, setPartnersCopy] = useState([]);
  useEffect(() => {
    if (search) {
      let arrayPartners = partnersCopy;
      arrayPartners = arrayPartners.filter((element) =>
        element.name.toLowerCase().includes(search.toLowerCase())
      );
      setPartners(arrayPartners);
    } else {
      setPartners(partnersCopy);
    }
  }, [search]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [favoritePartners, setFavoritePartners] = useState([]);
  useEffect(() => {
    getUser(userid)
      .then((res) => {
        if (res.status === 200 && res.success) {
          const data = res.data;
          const favorites = data.favorite_partner || [];
          setFavoritePartners(favorites);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error("500");
      });
  }, [userid]);

  return (
    <div className="background">
      <HeaderClientTransparent />
      {isMobile ? <NavClientHorizontal /> : <NavClient />}

      <div className="backgroundImageDashboardClient">
        <div className="leftcenter">
          <a className="titleDashboardClient">{t("hi")}</a>
          <a className="titleDashboardClient">{name}</a>
          <a className="subtitleDashboardClient">{t("whereto")}</a>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80px",
            paddingTop: "15vh",
          }}
        >
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${EventsBtn})` }}
            onClick={() => {
              navigate("/eventslist");
            }}
          ></button>
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${StationsBtn})` }}
            onClick={() => {
              navigate("/stationslist");
            }}
          ></button>
          <button
            className="btnDashboardClient"
            style={{ backgroundImage: `url(${PartnersBtn})` }}
          ></button>
        </div>
        <div className="centerContent2">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: 45,
              marginLeft: 10,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
            <p className="EventsTitle">{t("allPartners")}</p>
          </div>
          <Box
            sx={{
              width: "100%",
              height: "55vh",
              overflowY: "auto",
              marginLeft: isMobile ? "7%" : "0rem",
            }}
          >
            <Grid container spacing={2}>
              {loading
                ? Array.from(new Array(18)).map((_, index) => (
                    <Grid item xs={12} sm={6} md={4} lg={4} xl={4} key={index}>
                      <Skeleton
                        variant="rectangular"
                        height="10.625rem"
                        sx={{
                          borderRadius: "0.9375rem",
                          width: { xs: "82%", sm: "22.5625rem" },
                          margin: "0 0",
                        }}
                      />
                    </Grid>
                  ))
                : partners.map((partner, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      lg={4}
                      xl={4}
                      key={partner._id}
                    >
                      <div
                        className="event-container"
                        onClick={() =>
                          navigate("/partnerpage", {
                            state: { partnerId: partner._id },
                          })
                        }
                      >
                        <div
                          className="event-image2"
                          style={{
                            backgroundImage: `url(${partner.photo})`,
                          }}
                        ></div>
                        <div className="event-details">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "row",
                              width: "100%",
                              overflow: "hidden",
                            }}
                          >
                            <div className="event-title-name2">
                              {partner.name}
                            </div>
                            {/* <div className="event-date">
                              <div className="event-day-dark">
                                {new Date(event.start_date).getDate()}
                              </div>
                              <div className="event-month-dark">
                                {new Date(event.start_date).toLocaleString(
                                  "default",
                                  { month: "short" }
                                )}
                              </div>
                            </div> */}
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",
                                marginTop: "1rem",

                                overflow: "hidden",
                              }}
                            >
                              <span
                                style={{
                                  width: 11,
                                  height: 16,
                                  zIndex: 99,
                                  backgroundImage: `url(${locationIconSmall})`,
                                }}
                              />
                              <div
                                className="cardContent"
                                style={{ marginLeft: 10, overflow: "hidden" }}
                              >
                                {/* <a className="eventTextSmall">
                                  {event.station}
                                </a> */}
                                <a
                                  className="eventTextSmall"
                                  style={{ width: "7rem" }}
                                >
                                  {partner.address}
                                </a>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                flexDirection: "row",
                                width: "100%",
                                marginTop: 10,
                              }}
                            >
                              <span
                                style={{
                                  width: 11,
                                  height: 11,
                                  zIndex: 99,
                                  backgroundImage: `url(${phoneIconSmall})`,
                                }}
                              />
                              <div
                                className="cardContent"
                                style={{ marginLeft: 10 }}
                              >
                                <a
                                  className="eventTextSmall"
                                  style={{ width: "7rem" }}
                                >
                                  {partner.contact}
                                </a>
                              </div>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  marginLeft: "20px",
                                }}
                              >
                                 {favoritePartners.includes(partner._id) ? (
                                    <Star sx={{ color: "#FFD700" }} />
                                  ) : (
                                    <StarBorder sx={{ color: "#FFD700" }} />
                                  )}
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                  ))}
            </Grid>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default PartnersList;
