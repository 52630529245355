import React, { useEffect, useState } from "react";
import logo from "../../images/logo.svg";
import background from "../../images/background.png";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Switch,
  Skeleton,
  List,
  ListItem,
  Rating,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import i18next from "i18next";
import Header from "../../components/header.js";
import EditIcon from "@mui/icons-material/Edit";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { CalendarIcon } from "@mui/x-date-pickers/icons";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import "dayjs/locale/pt";
import "dayjs/locale/en-gb";
import feira from "../../images/example/feira.jpeg";
import {
  parse,
  isAfter,
  isBefore,
  isSameDay,
  differenceInDays,
} from "date-fns";
import Dropzone from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faPen, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import NavManager from "../../components/navManager.js";
import HeaderManagerTransparent from "../../components/headerManagerTransparent.js";
import HeaderManager from "../../components/headerManager.js";
import HeaderManagerWhite from "../../components/headerManagerWhite.js";
import {
  getActivities,
  getDistricts,
  getSubActivities,
  getTrainStations,
  getTypes,
} from "../../api/requests/Data.js";
import toast from "react-hot-toast";
import { deleteEvent, getEvent, putEvent } from "../../api/requests/Event.js";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { getPromoters } from "../../api/requests/Promoters.js";
import { getCouncils } from "../../api/requests/Council.js";
import { getEquipments } from "../../api/requests/Equipment.js";
import NavManagerHorizontal from "../../components/navManagerHorizontal.js";
import { deleteEvaluations, getEvaluations } from "../../api/requests/Evaluations.js";
import DeleteIcon from "@mui/icons-material/Delete";

function EventPageManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [edicao, setEdicao] = useState(true);
  const [editSubs, setEditSubs] = useState(true);
  const [trainstations, setTrainstations] = useState([]);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const { eventId } = location.state || {};

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuOpen = (event) => {
    if (!edicao) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  function goBack() {
    navigate("/eventsmanager");
  }
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [openDialog3, setOpenDialog3] = useState(false);

  const [openDialogRegister, setOpenRegisterDialog] = useState(false);

  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: isMobile?"100%":"60%",
  };

  const inputTrainStyle = {
    color: "#0F1111",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "white",
    width: isMobile?"100%":"60%",
  };

  const handleAllowRegisterChange = (event) => {
    setBooking(!booking);
  };

  const handleAllowPublicChange = (event) => {
    setAudience(!audience);
  };

  const handleMinimumAgeChange = (event) => {
    setMinAge(event.target.value);
  };

  const handlePromoterChange = (event) => {
    setPromoterId(event.target.value);
  };

  const handleCouncilChange = (event) => {
    setCouncilId(event.target.value);
  };

  const handleEquipmentChange = (event) => {
    setIdEquipment(event.target.value);
  };

  const today = dayjs();

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [startDateregister, setStartDateRegister] = useState(today);
  const [endDateregister, setEndDateRegister] = useState(today);

  const [value, setValue] = React.useState("+351999999999");

  const [audience, setAudience] = useState(true);
  const [audienceCapacity, setAudienceCapacity] = useState(0);
  const [audienceUrl, setAudienceUrl] = useState([]);
  const [booking, setBooking] = useState(true);
  const [bookingLimit, setBookingLimit] = useState(0);
  const [cancel, setCancel] = useState(false);
  const [cancelObs, setCancelObs] = useState("");
  const [district, setDistrict] = useState("");
  const [co2, setCo2] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [endRegDate, setEndRegDate] = useState(Date);
  const [eventUrl, setEventUrl] = useState("");
  const [free, setFree] = useState("");
  const [gallery, setGallery] = useState([]);
  const [idEquipment, setIdEquipment] = useState("");
  const [minAge, setMinAge] = useState(0);
  const [modality, setModality] = useState("");
  const [name, setName] = useState("");

  const [obs, setObs] = useState("");
  const [poster, setPoster] = useState([]);
  const [promoterId, setPromoterId] = useState("");
  const [councilId, setCouncilId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [startRegDate, setStartRegDate] = useState(Date);
  const [submodality, setSubmodality] = useState("0");
  const [urlFacebook, setUrlFacebook] = useState("");
  const [urlInstagram, setUrlInstagram] = useState("");
  const [urlLinkedin, setUrlLinkedin] = useState("");
  const [urlTwitter, setUrlTwitter] = useState("");
  const [urlYoutube, setUrlYoutube] = useState("");
  const [video, setVideo] = useState([]);
  const [visible, setVisible] = useState(true);
  const [districts, setDistricts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [promoters, setPromoters] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [types, setTypes] = useState([]);
  const [cityCouncils, setCityCouncils] = useState([]);
  const [culturalActivities, setCulturalActivities] = useState([]);
  const [sportsActivities, setSportsActivities] = useState([]);
  const [subactivities, setSubActivities] = useState([]);
  const [discountCode, setDiscountCode] = useState("");
  const [isLoadingDistricts, setLoadingDistricts] = useState(true);
  const [isLoadingTrainStations, setLoadingTrainStations] = useState(true);
  const [status, setStatus] = useState(0);
  const [data, setData] = useState([]);
  const [reason, setReason] = useState("");

  const handleChange = (newValue) => {
    matchIsValidTel(newValue, {
      onlyCountryies: [],
      excludedCountryies: [],
      continents: [],
    });
  };

  const handleDialogClose = () => {
    // if (reason) {
    deleteEvent(eventId)
      .then((res) => {
        if (res.success && res.status === 200) {
          navigate("/eventsmanager");
          toast.success(t("deleted"));
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    // } else {
    //   toast.error(t("reasonError"));
    // }
  };

  const handleDialogClose2 = () => {
    if (reason) {
      putEvent(eventId, {
        status: 2,
        obs: reason,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setOpenDialog2(false);
            setStatus(2);
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    } else {
      toast.error(t("reasonError"));
    }
  };

  const handleDialogClose3 = () => {
    if (reason) {
      putEvent(eventId, {
        status: 1,
        obs: reason,
      })
        .then((res) => {
          if (res.success && res.status === 200) {
            toast.success(t("successMessage"));
            setOpenDialog3(false);
            setStatus(1);
            setEdicao(!edicao);
          } else {
            toast.error(res.error);
          }
        })
        .catch((error) => {
          toast.error(t("500"));
        });
    } else {
      toast.error(t("reasonError"));
    }
  };

  const handleChangeType = (event) => {
    setTypeId(event.target.value);
  };

  const handleSwitchChange = (event) => {
    setVisible(event.target.checked);
  };

  const handleDelete = () => {
    handleMenuClose();
    setOpenDialog(true);
  };

  const handleSuspend = () => {
    handleMenuClose();
    setOpenDialog2(true);
  };

  const handleActivate = () => {
    handleMenuClose();
    setOpenDialog3(true);
  };

  const handleDialogRegisterClose = () => {
    setOpenRegisterDialog(false);
  };

  const confirmDelete = () => {
    setOpenDialog(false);
  };

  const [preview, setPreview] = useState(feira);
  const [preview1, setPreview1] = useState(feira);
  const [preview2, setPreview2] = useState(feira);
  const [preview3, setPreview3] = useState(feira);
  const [preview4, setPreview4] = useState(feira);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPoster(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const onDrop1 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview1(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop2 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview2(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop3 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview3(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const onDrop4 = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview4(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const options = [
    { label: t("name"), value: "name" },
    { label: t("age"), value: "age" },
    { label: t("phone"), value: "phone" },
    { label: t("email"), value: "email" },
    { label: t("company"), value: "company" },
  ];

  const icon = <CheckBoxOutlineBlankIcon fontSize="medium" />;
  const checkedIcon = <CheckBoxIcon fontSize="medium" />;

  const [selectedOptions, setSelectedOptions] = useState([
    { label: t("name"), value: "name" },
    { label: t("age"), value: "age" },
  ]);

  const handleChangeOption = (event, value) => {
    setSelectedOptions(value);
  };
  useEffect(() => {
    getTrainStations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTrainstations(res.data);
          setLoadingTrainStations(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getDistricts()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setDistricts(res.data);
          setLoadingDistricts(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getPromoters()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPromoters(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getCouncils()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setCityCouncils(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getEquipments()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setEquipments(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          let cultural = [];
          let sports = [];
          res.data.forEach((element) => {
            if (element.type_id == 1) {
              sports.push(element);
            } else if (element.type_id == 2) {
              cultural.push(element);
            }
          });
          setSportsActivities(sports);
          setCulturalActivities(cultural);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
    getTypes()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setTypes(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
    getSubActivities()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setSubActivities(res.data);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error("500");
      });
  }, []);

  useEffect(() => {
    if (!isLoadingDistricts && !isLoadingTrainStations) {
      getEvent(eventId)
        .then((res) => {
          if (res.status === 200 && res.success) {
            let data = res.data;
            setData(data);
            setAudience(data.audience);
            setAudienceCapacity(data.audience_capacity);
            setAudienceUrl(data.audience_url);
            setBooking(data.booking);
            setBookingLimit(data.booking_limit);
            setCancel(data.cancel);
            setCancelObs(data.cancel_obs);
            setDistrict(data.district[0]);
            setCo2(data.co2);
            setCoordinates(data.coordinates);
            setDiscount(data.discount);
            setEndRegDate(dayjs(data.end_reg_date));
            setEventUrl(data.event_url);
            setFree(data.free);
            setGallery(data.gallery);
            setIdEquipment(data.id_equipment);
            setMinAge(data.min_age);
            setModality(
              sportsActivities.find(
                (activity) => activity._id === data.modality
              ) ||
                culturalActivities.find(
                  (activity) => activity.id === data.modality
                ) ||
                null
            );
            setSubmodality(
              subactivities.find(
                (activity) => activity._id === data.submodality
              ) || null
            );
            setName(data.name);
            setObs(data.obs);
            setPoster(data.poster);
            setPromoterId(data.promoter_id);
            setCouncilId(data.city_council_id);
            setStartRegDate(dayjs(data.start_reg_date));
            setStartDate(dayjs(data.start_date));
            setEndDate(dayjs(data.end_date));
            setUrlFacebook(data.url_facebook);
            setUrlInstagram(data.url_instagram);
            setUrlLinkedin(data.url_linkedin);
            setUrlTwitter(data.url_twitter);
            setUrlYoutube(data.url_youtube);
            setVideo(data.video);
            setVisible(data.visible);
            setTypeId(data.type);
            setSelectedStation(data.station[0]);
            setDiscountCode(data.discount_code);
            setPreview(data.photo);
            setPreview1(data.gallery[0]);
            setPreview2(data.gallery[1]);
            setPreview3(data.gallery[2]);
            setPreview4(data.gallery[3]);
            setStatus(data.status);
            setLoading(false);
          } else {
            toast.error(res.error);
            setLoading(false);
          }
        })
        .catch((error) => {
          setLoading(false);

          toast.error("500");
        });
    }
  }, [isLoadingDistricts, isLoadingTrainStations]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const DateCell = ({ value }) => {
    const { t } = useTranslation();
    const today = new Date();

    const startConverted = new Date(startDate);
    const endConverted = new Date(endDate);

    const isPast = isAfter(today, endConverted);
    const isFuture = isBefore(today, startConverted);
    const isOngoing = !isPast && !isFuture;

    let statusText = "";
    let statusColor = "";

    if (isPast) {
      statusText = t("finished");
      statusColor = "#ED4F2C";
    } else if (isOngoing) {
      statusText = t("live");
      statusColor = "#13BB56";
    } else if (isFuture) {
      const daysToStart = differenceInDays(startConverted, today);
      statusText = t("in") + " " + daysToStart + " " + t("days");
      statusColor = "#B0B0B0";
    }

    return (
      <div style={{ display: "flex", alignItems: "center", marginTop: "20px" }}>
        <div
          style={{
            width: "12px",
            height: "12px",
            backgroundColor: statusColor,
            borderRadius: "3px",
          }}
        ></div>
        <a className="dateEventText" style={{ marginLeft: "8px" }}>
          {statusText}
        </a>
      </div>
    );
  };
  const [search, setSearch] = useState("");
  const filteredStations = trainstations.filter(
    (station) =>
      station.designation.toLowerCase().includes(search.toLowerCase()) ||
      station.county.toLowerCase().includes(search.toLowerCase()) ||
      station.district.toLowerCase().includes(search.toLowerCase()) ||
      station.parish.toLowerCase().includes(search.toLowerCase())
  );
  const [selectedStation, setSelectedStation] = useState(null);
  const [selectedStations, setSelectedStations] = useState([]);

  const handleDistrictChange = (event, newValue) => {
    setDistrict(newValue);
  };

  const handleStationChange = (event, value) => {
    setSelectedStation(value);
  };

  function saveData() {
    if (!preview) {
      toast.error(t("posterValidation"));
      return;
    }
    if (!startDate) {
      toast.error(t("startDateValidation"));
      return;
    }
    if (!endDate) {
      toast.error(t("endDateValidation"));
      return;
    }
    if (!name) {
      toast.error(t("nameEventValidation"));
      return;
    }
    if (!district) {
      toast.error(t("districtValidation"));
      return;
    }
    if (!typeId) {
      toast.error(t("typeIdValidation"));
      return;
    }
    if (!modality) {
      toast.error(t("modalityValidation"));
      return;
    }
    if (!submodality) {
      toast.error(t("submodalityValidation"));
      return;
    }
    // if (!free != "") {
    //   toast.error(t("freeValidation"));
    //   return;
    // }
    if (!coordinates) {
      toast.error(t("coordinatesValidation"));
      return;
    }

    putEvent(eventId, {
      name: name,
      poster: poster,
      start_date: startDate,
      end_date: endDate,
      start_reg_date: startRegDate,
      end_reg_date: endRegDate,
      free: free,
      booking: booking,
      coordinates: coordinates,
      gallery: [preview1, preview2, preview3, preview4],
      video: video,
      event_url: eventUrl,
      promoter_id: promoterId,
      city_council_id: councilId,
      min_age: minAge,
      booking_limit: bookingLimit,
      audience: audience,
      audience_capacity: audienceCapacity,
      audience_url: audienceUrl,
      visible: visible,
      discount: discount,
      discount_code: discountCode,
      url_facebook: urlFacebook,
      url_instagram: urlInstagram,
      url_youtube: urlYoutube,
      url_linkedin: urlLinkedin,
      url_twitter: urlTwitter,
      obs: obs,
      type: typeId,
      modality: modality,
      submodality: submodality,
      district_id: district.id,
      station: selectedStation.id,
      discount_code: discountCode,
      status: status,
    })
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("successMessage"));
          setEdicao(!edicao);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }
  const [filteredSubactivities, setFilteredSubactivities] =
    useState(subactivities);

  useEffect(() => {
    setFilteredSubactivities(
      subactivities.filter(
        (activity) => activity.id_modality.toString() === modality
      )
    );
  }, [modality, subactivities]);

  const handleModalityChange = (event, newValue) => {
    setModality(newValue);
  };

  const handleSubModalityChange = (event, newValue) => {
    setSubmodality(newValue);
  };

  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    getEvaluations()
      .then((res) => {
        if (res.status === 200 && res.success) {
          const filteredReviews = res.data.filter(
            (review) => review.type_id === eventId
          );
          setReviews(filteredReviews);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const handleDeleteReview = (index) => {
    deleteEvaluations(index)
      .then((res) => {
        if (res.success && res.status === 200) {
          toast.success(t("deleted"));
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(t("500"));
      });
    const updatedReviews = reviews.filter((review) => review._id !== index); 
    setReviews(updatedReviews);
  };
  return (
    <div className="background">
      <HeaderManagerWhite />
      {isMobile ? <NavManagerHorizontal /> : <NavManager />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            size={"small"}
            style={{ fill: "#044535", zIndex: 99 }}
          />
        </IconButton>
      </div>
      <div className="centerpagescroll">
        <a className="greenTitleClientData">{t("eventdata")}</a>
        <div className="whiteInfoCard2">
          {loading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={"15%"}
                height={"80%"}
                sx={{ margin: "1%", borderRadius: "0.9375rem" }}
              />
              <div className="mainInfoClient" style={{ paddingTop: "10px" }}>
                <Skeleton
                  variant="text"
                  width={"70%"}
                  height={40}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
                <Skeleton
                  variant="text"
                  width={150}
                  height={20}
                  sx={{ margin: "1%" }}
                />
              </div>
              <div
                className="mainInfoClient"
                style={{ paddingTop: "10px", width: "400px" }}
              >
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginBottom: "1%" }}
                />
                <Skeleton variant="text" width={210} height={20} />
                <Skeleton
                  variant="text"
                  width={100}
                  height={20}
                  sx={{ marginTop: "1%" }}
                />
                <Skeleton variant="text" width={210} height={20} />
              </div>
            </>
          ) : (
            <>
              <img className="imgClientCard" src={poster} />
              <div className="mainInfoClient" style={{ paddingTop: "10px" }}>
                <a className="infoClientName">{name}</a>
                <a className="infoEventText">{district[i18next.language]}</a>
                <a className="infoEventText" style={{ marginTop: "4%" }}>
                  {formatDate(startDate)}
                </a>
                <a className="infoEventText">{formatDate(endDate)}</a>
              </div>
              <div
                className="mainInfoClient"
                style={{ paddingTop: "10px", width: "400px" }}
              >
                <a className="infoClientTitleCard">{t("promoter")}</a>

                <a className="infoEventText">
                  Camara Municipal de Viana do Castelo
                </a>

                <a className="infoClientTitleCard" style={{ marginTop: "4%" }}>
                  {t("entrance")}
                </a>
                {free ? (
                  <a className="infoEventText">{t("free")}</a>
                ) : (
                  <a className="infoEventText">{t("notfree")}</a>
                )}
                <a className="infoEventText"></a>

                <DateCell value={startDate + " " + endDate} />
              </div>
              {/* <div className="infoClientTopRight">
            <a className="infoIdText">id:03</a>
          </div> */}
              <div className="infoClientBottomRight">
                <a
                  className={edicao ? "activeText" : "activeTextEdit"}
                  onClick={handleMenuOpen}
                >
                  {status == 1 ? t("active") : t("suspended")}
                  {!edicao ? <a className="arrowDown">▼</a> : null}
                </a>
              </div>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                sx={{
                  background: "transparent",

                  borderColor: "transparent",
                  marginTop: "25px",
                  width: "214px",
                  height: "auto",
                  borderRadius: "8px !important",
                }}
                MenuListProps={{
                  style: {
                    padding: "8px 0",
                    backgroundColor: "#F3F3F3",
                    borderRadius: "8px !important",
                  },
                }}
              >
                {status === 1 && (
                  <>
                    <MenuItem
                      onClick={handleSuspend}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("suspend")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleDelete}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("delete")}
                    </MenuItem>
                  </>
                )}
                {status === 2 && (
                  <>
                    <MenuItem
                      onClick={handleActivate}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("activate")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleDelete}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: "5px !important",
                        fontSize: "18px",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontFamily: "Montserrat",
                        color: "#044535",
                      }}
                    >
                      {t("delete")}
                    </MenuItem>
                  </>
                )}
              </Menu>
              <Dialog
                open={openDialog}
                onClose={handleDialogClose}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("deleteEvent")}</a>
                  </DialogContentText>
                  {/* <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  /> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button onClick={handleDialogClose} className="deleteBtn">
                      {t("delete")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={openDialogRegister}
                onClose={handleDialogRegisterClose}
                PaperProps={{
                  style: { borderRadius: 15, width: "100%", maxWidth: "800px" },
                }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel">{t("subscriptions")}</a>
                  </DialogContentText>
                  <Grid container spacing={2} padding={2}>
                    <Grid item xs={6}>
                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <a className="photoLabel">{t("allowRegister")}</a>
                        <TextField
                          fullWidth
                          disabled={edicao}
                          select
                          InputProps={{
                            disableUnderline: true,
                            style: {
                              ...inputPropsStyle,
                              textAlign: "left",
                              borderRadius: "8px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root ": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          value={booking}
                          onChange={handleAllowRegisterChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            getContentAnchorEl: null,
                          }}
                        >
                          <MenuItem value={true}>{t("yes")}</MenuItem>
                          <MenuItem value={false}>{t("no")}</MenuItem>
                        </TextField>
                      </div>
                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <a className="photoLabel">{t("startdateregister")}</a>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18next?.language}
                        >
                          <DatePicker
                            variant="standard"
                            value={startRegDate}
                            onChange={(e) => {
                              setStartRegDate(e);
                            }}
                            disabled={edicao || booking === false}
                            slotProps={{
                              tabs: {
                                hidden: false,
                              },
                              day: {
                                sx: {
                                  "&.MuiPickersDay-root.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                },
                              },
                              desktopPaper: {
                                sx: {
                                  borderRadius: "8px",
                                  ".MuiPickersYear-yearButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  ".MuiPickersMonth-monthButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                    {
                                      backgroundColor: "#13BB56 !important",
                                    },
                                  "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                    {
                                      color: "#13BB56 !important",
                                    },
                                  "& .css-1aquho2-MuiTabs-indicator": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                    {
                                      color: "#13BB56 !important",
                                    },
                                },
                              },
                            }}
                            sx={{
                              width: "60%",
                              "& .MuiInputBase-input.Mui-disabled": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#44b887 !important",
                                color: "white",
                              },
                              "& .MuiPickersCalendar-root": {
                                borderRadius: "8px !important",
                              },
                              "& .MuiInputLabel-root ": {
                                color: "#044535 !important",
                                fontFamily: "Montserrat",
                                fontSize: "18px !important",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                                position: "absolute",
                                marginTop: "-5px",
                                marginLeft: "-10px",
                              },
                              "& .MuiOutlinedInput-root": {
                                color: "#0F111199 !important",
                                textAlign: "right",
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "15px",
                                borderRadius: "8px",
                                background: !editSubs ? "#0f11110d" : "white",
                                borderColor: "transparent !important",
                                width: "100%",
                                height: "34.88px",
                                padding: "16.5px 10px",
                              },
                              "& .MuiInputLabel-root.Mui-disabled ": {
                                color: "#044535 !important",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                              },
                              "& .MuiOutlinedInput-notchedOutline ": {
                                border: "0px",
                              },
                              "& .MuiOutlinedInput-input": {
                                padding: "16.5px 0px",
                                width: "100%",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <a className="photoLabel">{t("enddateregister")}</a>
                        <LocalizationProvider
                          dateAdapter={AdapterDayjs}
                          adapterLocale={i18next?.language}
                        >
                          <DatePicker
                            variant="standard"
                            value={endRegDate}
                            onChange={(e) => {
                              setEndRegDate(e);
                            }}
                            disabled={edicao || booking === false}
                            slotProps={{
                              tabs: {
                                hidden: false,
                              },
                              day: {
                                sx: {
                                  "&.MuiPickersDay-root.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                },
                              },
                              desktopPaper: {
                                sx: {
                                  borderRadius: "8px",
                                  ".MuiPickersYear-yearButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  ".MuiPickersMonth-monthButton.Mui-selected": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                    {
                                      backgroundColor: "#13BB56 !important",
                                    },
                                  "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                    {
                                      color: "#13BB56 !important",
                                    },
                                  "& .css-1aquho2-MuiTabs-indicator": {
                                    backgroundColor: "#13BB56 !important",
                                  },
                                  "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                    {
                                      color: "#13BB56 !important",
                                    },
                                },
                              },
                            }}
                            sx={{
                              width: "60%",
                              "& .MuiInputBase-input.Mui-disabled": {
                                backgroundColor: "transparent",
                              },
                              "& .MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#44b887 !important",
                                color: "white",
                              },
                              "& .MuiPickersCalendar-root": {
                                borderRadius: "8px !important",
                              },
                              "& .MuiInputLabel-root ": {
                                color: "#044535 !important",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                                position: "absolute",
                                marginTop: "-5px",
                                marginLeft: "-10px",
                              },
                              "& .MuiOutlinedInput-root": {
                                color: "#0F111199 !important",
                                textAlign: "right",
                                fontFamily: "Montserrat",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: 500,
                                lineHeight: "15px",
                                borderRadius: "8px",
                                background: !editSubs ? "#0f11110d" : "white",
                                borderColor: "transparent !important",
                                width: "100%",
                                height: "34.88px",
                                padding: "16.5px 10px",
                              },
                              "& .MuiInputLabel-root.Mui-disabled ": {
                                color: "#044535 !important",
                                fontFamily: "Montserrat",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "15px",
                                borderColor: "transparent !important",
                              },
                              "& .MuiOutlinedInput-notchedOutline ": {
                                border: "0px",
                              },
                              "& .MuiOutlinedInput-input": {
                                padding: "16.5px 0px",
                                width: "100%",
                              },
                            }}
                          />
                        </LocalizationProvider>
                      </div>
                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <a className="photoLabel">{t("numberParticipants")}</a>
                        <TextField
                          fullWidth
                          disabled={edicao || booking === false}
                          type="number"
                          InputProps={{
                            disableUnderline: true,
                            style: inputPropsStyle,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root ": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          value={bookingLimit}
                          onChange={(e) => {
                            setBookingLimit(e.target.value);
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      {/* <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("registerData")}</a>
                    <Autocomplete
                      multiple
                      options={options}
                      disabled={editSubs || booking === false}
                      variant="standard"
                      sx={{
                        fontFamily: "Montserrat !important",
                        border: "0px",
                        borderBottom: "1px transparent !important",
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                        "& .MuiInputBase-root-MuiInput-root": {
                          fontFamily: "Montserrat !important",
                          border: "0px",
                        },
                      }}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.label}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{
                              fontFamily: "Montserrat !important",
                            }}
                            checked={selected}
                          />
                          {option.label}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            fontFamily: "Montserrat !important",
                            borderBottom: "1px transparent !important",
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root ": {
                              color: "#044535 !important",
                            },
                            ".MuiInputBase-root-MuiInput-root": {
                              fontFamily: "Montserrat !important",
                              borderBottom: "0px solid !important",
                            },
                            ".css-953pxc-MuiInputBase-root-MuiInput-root::after":
                              {
                                borderBottom: "0px solid !important",
                              },
                            ".css-953pxc-MuiInputBase-root-MuiInput-root::before":
                              {
                                borderBottom: "0px solid !important",
                              },
                          }}
                          variant="standard"
                        />
                      )}
                      value={selectedOptions}
                      onChange={handleChangeOption}
                    />
                  </div> */}
                      {/* <div
                    style={{
                      marginTop: "30px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <a className="photoLabel">{t("price")}</a>
                    <TextField
                      fullWidth
                      disabled={editSubs || booking === false}
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={50}
                    />
                  </div> */}
                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <a className="photoLabel">{t("discount")}</a>
                        <TextField
                          type="number"
                          fullWidth
                          disabled={edicao || booking === false}
                          InputProps={{
                            disableUnderline: true,
                            style: inputPropsStyle,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root ": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          onChange={(e) => {
                            setDiscount(e.target.value);
                          }}
                          value={discount}
                        />
                      </div>
                      <div
                        style={{
                          marginTop: "30px",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <a className="photoLabel">{t("discountCode")}</a>
                        <TextField
                          fullWidth
                          disabled={edicao || booking === false}
                          InputProps={{
                            disableUnderline: true,
                            style: inputPropsStyle,
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          onChange={(e) => {
                            setDiscountCode(e.target.value);
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root ": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          value={discountCode}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Dialog>
              <Dialog
                open={openDialog2}
                onClose={handleDialogClose2}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("suspendEvent")}</a>
                  </DialogContentText>

                  <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button onClick={handleDialogClose2} className="suspendBtn">
                      {t("suspend")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog2(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
              <Dialog
                open={openDialog3}
                onClose={handleDialogClose3}
                PaperProps={{ style: { borderRadius: 15 } }}
              >
                <DialogContent
                  style={{
                    borderRadius: "15px !important",
                    background: "#FFF",
                  }}
                >
                  <DialogContentText
                    style={{
                      background: "#FFF",
                      marginTop: "20px",
                      marginBottom: "10px",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a className="textCancel"> {t("activateEvent")}</a>
                  </DialogContentText>
                  <TextField
                    required
                    variant="outlined"
                    placeholder={t("reason")}
                    multiline
                    rows={2}
                    style={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    inputProps={{ maxLength: 255 }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      onClick={handleDialogClose3}
                      className="activateBtn"
                    >
                      {t("activate")}
                    </Button>
                    <Button
                      onClick={() => {
                        setOpenDialog3(false);
                      }}
                      className="cancelBtn"
                    >
                      {t("cancel")}
                    </Button>
                  </div>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
        <div className="whiteEditInfoCard2">
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              flexDirection: "column",
              maxWidth: "none",
              maxHeight: "80%",
              overflow: "auto",
            }}
          >
            {isMobile?<div className="photosEvent">
                  {edicao ? (
                    <div className="photoColumn">
                      <a className="photoLabel">{t("poster")}*</a>
                      <div className="addPoster">
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            width={"4.0625rem"}
                            height={"4.0625rem"}
                            sx={{ borderRadius: "0.625rem" }}
                          />
                        ) : (
                          <img
                            src={poster}
                            className="addPoster"
                            alt="Poster"
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="photoColumn">
                      <a className="photoLabel">{t("poster")}</a>
                      <Dropzone
                        onDrop={onDrop}
                        accept="image/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {loading ? (
                                <Skeleton
                                  variant="rectangular"
                                  width={"4.0625rem"}
                                  height={"4.0625rem"}
                                  sx={{ borderRadius: "0.625rem" }}
                                />
                              ) : !preview ? (
                                <div className="addPhoto">
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div className="addPosterEdit">
                                  <img src={poster} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faPen}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  )}

                  <div className="morePhotos">
                    <a className="photoLabel">{t("photosvideos")}</a>
                    <div className="photoRow">
                      {edicao ? (
                        <>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview1 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview1} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview2 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview2} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview3 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview3} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview4 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview4} className="addPoster" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview1(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop1}
                              disabled={preview1 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview1 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview1}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview2(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop2}
                              disabled={preview2 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview2 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview2}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview3(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop3}
                              accept="image/*"
                              disabled={preview3 ? true : false}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview3 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview3}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview4(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop4}
                              accept="image/*"
                              multiple={false}
                              disabled={preview4 ? true : false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview4 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview4}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                :null}
            <Grid container spacing={2} padding={2}>
              <Grid item xs={6}>
                {isMobile?null:<div className="photosEvent">
                  {edicao ? (
                    <div className="photoColumn">
                      <a className="photoLabel">{t("poster")}*</a>
                      <div className="addPoster">
                        {loading ? (
                          <Skeleton
                            variant="rectangular"
                            width={"4.0625rem"}
                            height={"4.0625rem"}
                            sx={{ borderRadius: "0.625rem" }}
                          />
                        ) : (
                          <img
                            src={poster}
                            className="addPoster"
                            alt="Poster"
                          />
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="photoColumn">
                      <a className="photoLabel">{t("poster")}</a>
                      <Dropzone
                        onDrop={onDrop}
                        accept="image/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div className="addPoster" {...getRootProps()}>
                              <input {...getInputProps()} />
                              {loading ? (
                                <Skeleton
                                  variant="rectangular"
                                  width={"4.0625rem"}
                                  height={"4.0625rem"}
                                  sx={{ borderRadius: "0.625rem" }}
                                />
                              ) : !preview ? (
                                <div className="addPhoto">
                                  <FontAwesomeIcon
                                    style={{
                                      fontSize: "16px",
                                      color: "rgba(15, 17, 17, 0.60)",
                                    }}
                                    icon={faPlus}
                                  />
                                </div>
                              ) : (
                                <div className="addPosterEdit">
                                  <img src={poster} className="addPoster" />
                                  <div className="overlay">
                                    <FontAwesomeIcon
                                      className="hoverIcon"
                                      icon={faPen}
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          </section>
                        )}
                      </Dropzone>
                    </div>
                  )}

                  <div className="morePhotos">
                    <a className="photoLabel">{t("photosvideos")}</a>
                    <div className="photoRow">
                      {edicao ? (
                        <>
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview1 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview1} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview2 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview2} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview3 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview3} className="addPoster" />
                            </div>
                          ) : null}
                          {loading ? (
                            <Skeleton
                              variant="rectangular"
                              width={"4.0625rem"}
                              height={"4.0625rem"}
                              sx={{
                                borderRadius: "0.625rem",
                                marginRight: "10px",
                              }}
                            />
                          ) : preview4 != null ? (
                            <div
                              className="addPoster"
                              style={{ marginRight: "10px" }}
                            >
                              <img src={preview4} className="addPoster" />
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview1(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop1}
                              disabled={preview1 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview1 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview1}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview2(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop2}
                              disabled={preview2 ? true : false}
                              accept="image/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview2 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview2}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview3(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop3}
                              accept="image/*"
                              disabled={preview3 ? true : false}
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview3 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview3}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                          <div
                            style={{ marginRight: "10px" }}
                            onClick={() => {
                              setPreview4(null);
                            }}
                          >
                            <Dropzone
                              onDrop={onDrop4}
                              accept="image/*"
                              multiple={false}
                              disabled={preview4 ? true : false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <section>
                                  <div
                                    className="addPoster"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    {!preview4 ? (
                                      <div
                                        className="addPhoto"
                                        style={{ marginRight: "10px" }}
                                      >
                                        <FontAwesomeIcon
                                          style={{
                                            fontSize: "16px",
                                            color: "rgba(15, 17, 17, 0.60)",
                                          }}
                                          icon={faPlus}
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        className="addPosterEdit"
                                        style={{
                                          marginRight: "10px !important",
                                        }}
                                      >
                                        <img
                                          src={preview4}
                                          className="addPoster"
                                        />
                                        <div className="overlay">
                                          <FontAwesomeIcon
                                            className="hoverIcon"
                                            icon={faTrashCan}
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </section>
                              )}
                            </Dropzone>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>}
                
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("startdate")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DateTimePicker
                        variant="standard"
                        showTimeSelect
                        value={startDate}
                        onChange={(e) => {
                          setStartDate(e);
                        }}
                        disabled={edicao}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },

                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },

                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width:isMobile?"100%": "60%",

                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "white",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },

                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",

                            fontFamily: "Montserrat",
                            fontSize: "18px !important",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            background: !edicao ? "#0f11110d" : "white",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      ></DateTimePicker>
                    </LocalizationProvider>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("enddate")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale={i18next?.language}
                    >
                      <DateTimePicker
                        variant="standard"
                        showTimeSelect
                        value={endDate}
                        onChange={(e) => {
                          setEndDate(e);
                        }}
                        disabled={edicao}
                        slotProps={{
                          tabs: {
                            hidden: false,
                          },

                          day: {
                            sx: {
                              "&.MuiPickersDay-root.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                            },
                          },

                          desktopPaper: {
                            sx: {
                              borderRadius: "8px",
                              ".MuiPickersYear-yearButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              ".MuiPickersMonth-monthButton.Mui-selected": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e3wlyl-MuiButtonBase-root-MuiMenuItem-root-MuiMultiSectionDigitalClockSection-item.Mui-selected":
                                {
                                  backgroundColor: "#13BB56 !important",
                                },
                              "& .css-y0fr9s-MuiButtonBase-root-MuiTab-root.Mui-selected":
                                {
                                  color: "#13BB56 !important",
                                },
                              "& .css-1aquho2-MuiTabs-indicator": {
                                backgroundColor: "#13BB56 !important",
                              },
                              "& .css-1e6y48t-MuiButtonBase-root-MuiButton-root":
                                {
                                  color: "#13BB56 !important",
                                },
                            },
                          },
                        }}
                        sx={{
                          width:isMobile?"100%": "60%",

                          "& .MuiInputBase-input.Mui-disabled": {
                            backgroundColor: "white",
                          },
                          "& .MuiPickersDay-root.Mui-selected": {
                            backgroundColor: "#44b887 !important",
                            color: "white",
                          },
                          "& .MuiPickersCalendar-root": {
                            borderRadius: "8px !important",
                          },

                          "& .MuiInputLabel-root ": {
                            color: "#044535 !important",

                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                            position: "absolute",
                            marginTop: "-5px",
                            marginLeft: "-10px",
                          },
                          "& .MuiOutlinedInput-root": {
                            color: "#0F111199 !important",
                            textAlign: "right",
                            fontFamily: "Montserrat",
                            fontSize: "18px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "15px",
                            borderRadius: "8px",
                            background: !edicao ? "#0f11110d" : "white",
                            borderColor: "transparent !important",
                            width: "100%",
                            height: "34.88px",
                            padding: "16.5px 10px",
                          },
                          "& .MuiInputLabel-root.Mui-disabled ": {
                            color: "#044535 !important",
                            fontFamily: "Montserrat",
                            fontSize: "16px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "15px",
                            borderColor: "transparent !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline ": {
                            border: "0px",
                          },
                          "& .MuiOutlinedInput-input": {
                            padding: "16.5px 0px",
                            width: "100%",
                          },
                        }}
                      ></DateTimePicker>
                    </LocalizationProvider>
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("event")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      multiline={true}
                      rows={2}
                      variant="standard"
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={name}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("allowRegister")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={true}
                      onClick={() => {
                        setOpenRegisterDialog(true);
                      }}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={booking}
                      onChange={handleAllowRegisterChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={true}>{t("yes")}</MenuItem>
                      <MenuItem value={false}>{t("no")}</MenuItem>
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("allowPublic")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={audience}
                      onChange={handleAllowPublicChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={true}>{t("yes")}</MenuItem>
                      <MenuItem value={false}>{t("no")}</MenuItem>
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("free")}?*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={free}
                      onChange={(e) => {
                        setFree(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value={true}>{t("yes")}</MenuItem>
                      <MenuItem value={false}>{t("no")}</MenuItem>
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("audienceCapacity")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      type="number"
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={audienceCapacity}
                      onChange={(e) => {
                        setAudienceCapacity(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("audienceURL")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      // margin="normal"
                      value={audienceUrl}
                      onChange={(e) => {
                        setAudienceUrl(e.target.value);
                      }}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("minimumAge")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      type="number"
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={minAge}
                      onChange={handleMinimumAgeChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("observations")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={80}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      multiline={true}
                      rows={4}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      onChange={(e) => {
                        setObs(e.target.value);
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={obs}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("promoter")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={promoterId}
                      onChange={handlePromoterChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {promoters.map((promoter) => (
                        <MenuItem key={promoter._id} value={promoter._id}>
                          {promoter.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("cityCouncil")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={councilId}
                      onChange={handleCouncilChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {cityCouncils.map((council) => (
                        <MenuItem key={council._id} value={council._id}>
                          {council.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("equipment")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={idEquipment}
                      onChange={handleEquipmentChange}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {equipments.map((equipment) => (
                        <MenuItem key={equipment._id} value={equipment._id}>
                          {equipment.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("district")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      disabled={edicao}
                      options={districts}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={district}
                      onChange={handleDistrictChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              ...inputPropsStyle,
                              textAlign: "left",
                              borderRadius: "8px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                        />
                      )}
                    />
                  )}
                </div>
              </Grid>

              <Grid item xs={6}>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("visibility")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={edicao}
                          checked={visible}
                          onChange={handleSwitchChange}
                          color="primary"
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": {
                              color: "#FFFFFF !important",
                            },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                              {
                                backgroundColor: "#13BB56 !important",
                                opacity: "1 !important",
                              },
                          }}
                        />
                      }
                      label={t("visible")}
                      style={{ marginTop: "20px" }}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          flexGrow: 1,
                          textAlign: "left",
                          marginLeft: "10px",
                          color: "#0F1111",
                          fontFamily: "Montserrat",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "normal",
                        },
                      }}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("coordinates")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      onChange={(e) => {
                        setCoordinates(e.target.value);
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={coordinates}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("nearStation")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      options={filteredStations}
                      getOptionLabel={(option) => option.designation}
                      value={selectedStation}
                      onChange={handleStationChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              ...inputPropsStyle,
                              textAlign: "left",
                              borderRadius: "8px",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                        />
                      )}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventLink")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setEventUrl(e.target.value);
                      }}
                      value={eventUrl}
                    />
                  )}
                </div>

                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventVideo")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setVideo(e.target.value);
                      }}
                      value={video}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventFacebook")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlFacebook(e.target.value);
                      }}
                      value={urlFacebook}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventInstagram")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlInstagram(e.target.value);
                      }}
                      value={urlInstagram}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventYoutube")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlYoutube(e.target.value);
                      }}
                      value={urlYoutube}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventLinkedin")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlLinkedin(e.target.value);
                      }}
                      value={urlLinkedin}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("eventTwitter")}</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      InputProps={{
                        disableUnderline: true,
                        style: inputPropsStyle,
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root ": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      onChange={(e) => {
                        setUrlTwitter(e.target.value);
                      }}
                      value={urlTwitter}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("type")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <TextField
                      fullWidth
                      disabled={edicao}
                      select
                      InputProps={{
                        disableUnderline: true,
                        style: {
                          ...inputPropsStyle,
                          textAlign: "left",
                          borderRadius: "8px",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        style: inputLabelPropsStyle,
                      }}
                      sx={{
                        "& .Mui-disabled": {
                          backgroundColor: "white !important",
                          color: "#044535 !important",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#044535 !important",
                        },
                      }}
                      variant="standard"
                      value={typeId}
                      onChange={handleChangeType}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      {types.map((type) => (
                        <MenuItem key={type._id} value={type.id}>
                          {type[i18next.language]}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("modality")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      disabled={edicao}
                      options={
                        typeId === "1" ? sportsActivities : culturalActivities
                      }
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={modality}
                      onChange={handleModalityChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <a className="photoLabel">{t("submodality")}*</a>
                  {loading ? (
                    <Skeleton
                      variant="rectangular"
                      width="60%"
                      height={40}
                      sx={{ borderRadius: "0.9375rem" }}
                    />
                  ) : (
                    <Autocomplete
                      disabled={edicao}
                      options={filteredSubactivities}
                      getOptionLabel={(option) =>
                        option[i18next.language] || option.en
                      }
                      value={submodality}
                      onChange={handleSubModalityChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          disabled={edicao}
                          InputProps={{
                            ...params.InputProps,
                            disableUnderline: true,
                            style: {
                              textAlign: "left",
                              borderRadius: "8px",
                              color: "#0F111199",
                              fontFamily: "Montserrat",
                              fontSize: "18px",
                              fontStyle: "normal",
                              fontWeight: 500,
                              lineHeight: "22px",
                              borderRadius: "8px",
                              background: "#0f11110d",
                              paddingLeft: "20px",
                              width: "60%",
                            },
                          }}
                          InputLabelProps={{
                            shrink: true,
                            style: inputLabelPropsStyle,
                          }}
                          sx={{
                            "& .Mui-disabled": {
                              backgroundColor: "white !important",
                              color: "#044535 !important",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#044535 !important",
                            },
                          }}
                          variant="standard"
                          margin="normal"
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option.id}>
                          {option[i18next.language] || option.en}
                        </li>
                      )}
                      sx={{
                        "& .MuiAutocomplete-popupIndicator": {
                          display: "none",
                        },
                        "& .MuiAutocomplete-clearIndicator": {
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          padding: 0,
                        },
                        "& .MuiAutocomplete-listbox": {
                          fontFamily: "Montserrat",
                          fontSize: "14px",
                        },
                        "& .MuiAutocomplete-inputRoot": {
                          padding: "0 !important",
                        },
                      }}
                    />
                  )}
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="bottomRowEdit">
            <button
              className="clientInfoEditButton2"
              onClick={() => {
                if (edicao) {
                  setEdicao(!edicao);
                } else {
                  saveData();
                }
              }}
            >
              {edicao ? t("edit") : t("save")}
            </button>
            {!edicao ? (
              <button
                className="clientInfoCancelButton2"
                onClick={() => {
                  setAudience(data.audience);
                  setAudienceCapacity(data.audience_capacity);
                  setAudienceUrl(data.audience_url);
                  setBooking(data.booking);
                  setBookingLimit(data.booking_limit);
                  setCancel(data.cancel);
                  setCancelObs(data.cancel_obs);
                  setDistrict(data.district[0]);
                  setCo2(data.co2);
                  setCoordinates(data.coordinates);
                  setDiscount(data.discount);
                  setEndRegDate(dayjs(data.end_reg_date));
                  setEventUrl(data.event_url);
                  setFree(data.free);
                  setGallery(data.gallery);
                  setIdEquipment(data.id_equipment);
                  setMinAge(data.min_age);
                  setModality(
                    sportsActivities.find(
                      (activity) => activity._id === data.modality
                    ) ||
                      culturalActivities.find(
                        (activity) => activity.id === data.modality
                      ) ||
                      null
                  );
                  setSubmodality(
                    subactivities.find(
                      (activity) => activity._id === data.submodality
                    ) || null
                  );
                  setName(data.name);
                  setObs(data.obs);
                  setPoster(data.poster);
                  setPromoterId(data.promoter_id);
                  setCouncilId(data.city_council_id);
                  setStartRegDate(dayjs(data.start_reg_date));
                  setStartDate(dayjs(data.start_date));
                  setEndDate(dayjs(data.end_date));
                  setUrlFacebook(data.url_facebook);
                  setUrlInstagram(data.url_instagram);
                  setUrlLinkedin(data.url_linkedin);
                  setUrlTwitter(data.url_twitter);
                  setUrlYoutube(data.url_youtube);
                  setVideo(data.video);
                  setVisible(data.visible);
                  setTypeId(data.type);
                  setSelectedStation(data.station[0]);
                  setDiscountCode(data.discount_code);
                  setPreview1(data.gallery[0]);
                  setPreview2(data.gallery[1]);
                  setPreview3(data.gallery[2]);
                  setPreview4(data.gallery[3]);
                  setStatus(data.status);
                  setEdicao(!edicao);
                }}
              >
                {" "}
                {t("cancel")}
              </button>
            ) : null}
          </div>
        </div>
        <div className="whiteEditInfoCardEvaluation">
          <Container
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
              width: "100%",
              maxHeight: isMobile ? "29vh" : "80%",
              overflow: "auto",
              marginTop: "15px",
            }}
          >
            <Typography variant="h5" gutterBottom>
              {t("reviews")}
            </Typography>

            {reviews.length === 0 ? ( // Verifica se o array de reviews está vazio
              <Typography
                variant="body2"
                style={{
                  marginTop: "20px",
                  color: "gray",
                  fontFamily: "Montserrat, sans-serif",
                }}
              >
                {t("noReviews")} {/* Texto traduzido */}
              </Typography>
            ) : (
              <List style={{ width: "80%" }}>
                {reviews.map((review) => (
                  <ListItem
                    key={review._id} // Certifique-se de usar uma key única
                    style={{
                      borderBottom: "1px solid #ccc",
                      padding: "10px 0",
                      width: "100%",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <Rating
                        value={review.rating}
                        readOnly
                        style={{ color: "#11bb55" }}
                      />
                      <Typography
                        variant="body2"
                        style={{
                          marginTop: "5px",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        {review.obs}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{
                          color: "gray",
                          fontFamily: "Montserrat, sans-serif",
                        }}
                      >
                        {new Date(review.datetime).toLocaleDateString()}
                      </Typography>
                    </div>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => handleDeleteReview(review._id)}
                    >
                      <DeleteIcon style={{ color: "#cccc" }} />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            )}
          </Container>
        </div>
      </div>
    </div>
  );
}

export default EventPageManager;
