import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import { getPromoters } from "../../api/requests/Promoters";
import Cookies from "js-cookie";
import NavManager from "../../components/navManager";
import HeaderManager from "../../components/headerManager";
import HeaderManagerWhite from "../../components/headerManagerWhite";
import LinkIcon from "@mui/icons-material/Link";
import CloseIcon from "@mui/icons-material/Close";

import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { getManager } from "../../api/requests/Managers";
import { registerLink } from "../../api/requests/Emails";
import NavManagerHorizontal from "../../components/navManagerHorizontal";

function PromotersManager() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const ownerId = Cookies.get("owner_id");
  function goBack() {
    navigate("/dashboardmanager");
  }
  
  const [promoters, setPromoters] = useState([]);
  const [promotersCopy, setPromotersCopy] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const inputLabelPropsStyle = {
    color: "#044535 !important",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "22px",
  };

  const inputPropsStyle = {
    color: "#0F111199",
    textAlign: "right",
    fontFamily: "Montserrat",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    borderRadius: "8px",
    background: "#0f11110d",
    paddingLeft: "10px",
    width: "92%",
  };

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function sendRegisterLink() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const link = `${window.location.origin}/promotersbyowner/${ownerId}`;

    if (email) {
      if (!emailRegex.test(email)) {
        toast.error(t("emailError"));
      } else {
        registerLink({
          email: email,
          link: link,
          ownerId: ownerId,
        })
          .then((res) => {
            if (res.status === 200) {
              toast.success(res.data.message);
            } else {
              toast.error(res.error || t("500"));
            }
          })
          .catch((error) => {
            toast.error(t("500"));
          });
      }
    } else {
      toast.error(t("emailError"));
    }
    handleClose();
  }

  useEffect(() => {
    // const id = Cookies.get("id");
    // const token = Cookies.get("token");

    // //If don't have an ID or token
    // if (!id && !token) {
    //   navigate("/loginadmin");
    //   //Remove the data and forward to the Login page
    //   Cookies.remove("id");
    //   Cookies.remove("email");
    //   Cookies.remove("token");
    //   Cookies.remove("first_name");
    //   Cookies.remove("last_name");
    //   Cookies.remove("photo");
    // }

    // Busca o manager para obter o municipality_id
    const id = Cookies.get("id");
    getManager(id)
      .then((res) => {
        if (res.success && res.status === 200) {
          const managerMunicipalityId = res.data.municipality_id;

          // Agora busca os promoters
          getPromoters()
            .then((promoterRes) => {
              if (promoterRes.status === 200 && promoterRes.success) {
                // Filtra os promoters pelo municipality_id do manager
                const filteredPromoters = promoterRes.data.filter(
                  (promoter) =>
                    promoter.municipality_id === managerMunicipalityId
                );

                // Atualiza o estado com os promoters filtrados
                setPromoters(filteredPromoters);
                setPromotersCopy(filteredPromoters);
                setLoading(false);
              } else {
                toast.error(promoterRes.error);
              }
            })
            .catch(() => {
              toast.error(t("500"));
            });
        } else {
          toast.error(res.error);
        }
      })
      .catch(() => {
        toast.error(t("500"));
      });
  }, []);

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop:isMobile?"0px": "-50px",
          marginBottom: isMobile?"0px":"30px",
          marginRight: "0px",
        }}
      >
        <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayPromoters = promotersCopy;
      arrayPromoters = arrayPromoters.filter(
        (element) =>
          // Elements to search for
          element.name.toLowerCase().includes(search.toLowerCase()) ||
          element.zone_name.toLowerCase().includes(search.toLowerCase()) ||
          element.district_name.toLowerCase().includes(search.toLowerCase())
      );
      setPromoters(arrayPromoters);
    } else {
      setPromoters(promotersCopy);
    }
  }, [search]);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const EventCell = ({ row }) => {
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      };

      updateWidth();
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
    }, []);

    const maxLength = Math.floor(containerWidth / 3.5);

    return (
      <div
        ref={containerRef}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
        }}
      >
        <img src={row.photo} alt={row.name} className="imageGrid" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            gap: "5px",
            width: "100%",
          }}
        >
          <span className="eventGridText">
            {truncateText(row.name, maxLength)}
          </span>
        </div>
      </div>
    );
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <HeaderManagerWhite />
      {isMobile ? <NavManagerHorizontal /> : <NavManager />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
        <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("promoters")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: isMobile?15:45,
              marginLeft: isMobile?10:30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            {/* <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {}}
            ></span> */}
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />

            <button
              className="invertible-button"
              onClick={() => {
                const link = `${window.location.origin}/promotersbyowner/${ownerId}`;

                navigator.clipboard
                  .writeText(link)
                  .then(() => alert("Link copiado para o clipboard!"))
                  .catch((err) =>
                    alert("Erro ao copiar o link: " + err.message)
                  );
              }}
            >
              <LinkIcon
                style={{
                  fontSize: "1rem",
                  marginRight: "0.5rem",
                }}
              />
              {t("linkRegisto")}
            </button>
            <button className="invertible-button" onClick={handleOpen}>
              <EmailOutlinedIcon
                style={{
                  fontSize: "1rem",
                  marginRight: "0.5rem",
                }}
              />
              {t("Enviar por Email")}
            </button>
            <Dialog
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  borderRadius: "10px",
                  padding: "1rem",
                  backgroundColor: "#f9f9f9",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
                  width: isMobile?"60%":"50%",
                  overflowX:"hidden"
                },
              }}
            >
              <IconButton
                onClick={handleClose}
                style={{
                  position: "absolute",
                  top: "0.5rem",
                  right: "0.5rem",
                  color: "#044535",
                  zIndex: 10,
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogTitle
                style={{
                  textAlign: "center",
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                  color: "#044535",
                }}
              >
                {t("Enviar Link por Email")}
              </DialogTitle>
              <DialogContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                  padding:12
           
                }}
              >
                <div
                  style={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <a className="photoLabel">{t("email")}</a>

                  <TextField
                    fullWidth
                    InputProps={{
                      disableUnderline: true,
                      style: inputPropsStyle,
                    }}
                    InputLabelProps={{
                      shrink: true,
                      style: inputLabelPropsStyle,
                    }}
                    sx={{
                      width: "100%",
                      "& .Mui-disabled": {
                        backgroundColor: "white !important",
                        color: "#044535 !important",
                      },
                      "& .MuiInputLabel-root ": {
                        color: "#044535 !important",
                      },
                    }}
                    variant="standard"
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    value={email}
                  />
                </div>
              </DialogContent>
              <DialogActions
                style={{
                  justifyContent: "center",
                  paddingBottom: "1rem",
                  gap: "1rem",
                }}
              >
                <Button
                  onClick={() => {
                    sendRegisterLink();
                  }}
                  variant="contained"
                  style={{
                    backgroundColor: "#044535",
                    color: "#fff",
                    borderRadius: "5px",
                    padding: "0.5rem 1.5rem",
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  {t("Enviar")}
                </Button>
              </DialogActions>
            </Dialog>
          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                className={"grid"}
                rowHeight={80}
                autoPageSize={true}
                loading={loading}
                columns={[
                  {
                    field: "promoter",
                    headerName: t("promoter"),
                    type: "string",
                    flex: 2.5,
                    renderCell: (params) => <EventCell row={params.row} />,
                  },

                  ...(isMobile
                    ? [] 
                    : [
                        {
                          field: "zone_name",
                          headerName: t("zone"),
                          flex: 1,
                          type: "string",
                          headerAlign:"center",
                  align:"center",
                        },
                        {
                          field: "district_name",
                          headerName: t("district"),
                          flex: 1,
                          type: "string",
                          headerAlign:"center",
                  align:"center",
                        },
                      ]),
                  {
                    field: "actions",
                    type: "actions",
                    headerName: t("actions"),
                    flex: 1,
                    cellClassName: "actions",
                    getActions: ({ id }) => {
                      return [
                        <GridActionsCellItem
                          icon={
                            <FontAwesomeIcon
                              style={{ fontSize: 18, color: "#0F1111" }}
                              icon={faPen}
                            />
                          }
                          label="Save"
                          onClick={() => {
                            navigate("/promoterpagemanager", {
                              state: { promoterId: id },
                            });
                          }}
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell": {
                    border: "none",
                    padding: "0 !important",
                    display: "flex",
                    alignItems: "center",
                  },
                  ".MuiDataGrid-filler": {
                    display: "none",
                  },

                  "& .MuiDataGrid-row.Mui-hovered": {
                    backgroundColor: "rgba(17, 96, 48, 0.20)",
                  },

                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "rgba(17, 96, 48, 0.20)",
                  },
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  '& .MuiDataGrid-cell[data-field="local"]': {
                    fontSize: "20px",
                  },
                  '& .MuiDataGrid-cell[data-field="registered"]': {
                    fontSize: "18px",
                  },
                  "& .MuiTablePagination-root ": {
                    overflow: "hidden !important",
                  },
                }}
                rows={promoters}
                checkboxSelection={false}
                onRowClick={(params) => {}}
                getRowClassName={() => "custom-row"}
                getRowId={(row) => row._id}
                localeText={
                  i18n.language == "pt"
                    ? ptPT.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  LoadingOverlay: <CircularProgress />,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <button
                className="clientInfoEditButton"
                onClick={() => {
                  navigate("/promotercreatemanager");
                }}
              >
                {t("addPromoter")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromotersManager;
