import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import Header from "../../components/header";
import NavAdmin from "../../components/navAdmin";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Search1 from "../../components/Search1";
import {
  DataGrid,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { esES, ptPT, enUS } from "@mui/x-data-grid/locales";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { getEquipments } from "../../api/requests/Equipment";
import toast from "react-hot-toast";
import Cookies from "js-cookie";
import NavManager from "../../components/navManager";
import {
  Checkbox,
  CircularProgress,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import HeaderManager from "../../components/headerManager";
import HeaderManagerWhite from "../../components/headerManagerWhite";
import NavManagerHorizontal from "../../components/navManagerHorizontal";

function EquipmentsManager() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  function goBack() {
    navigate("/dashboardmanager");
  }
  const [equipments, setEquipments] = useState([]);
  const [equipmentsCopy, setEquipmentsCopy] = useState([]);
  const [loading, setLoading] = useState(true);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  const theme = createTheme({
    components: {
      MuiCircularProgress: {
        styleOverrides: {
          root: {
            color: "#13BB56",
          },
        },
      },
    },
  });

  const EventCell = ({ row }) => {
    const containerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState(0);

    useEffect(() => {
      const updateWidth = () => {
        if (containerRef.current) {
          setContainerWidth(containerRef.current.offsetWidth);
        }
      };

      updateWidth();
      window.addEventListener("resize", updateWidth);
      return () => window.removeEventListener("resize", updateWidth);
    }, []);

    const maxLength = Math.floor(containerWidth / 3.5);

    return (
      <div
        ref={containerRef}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          margin: 0,
          padding: 0,
        }}
      >
        <img src={row.photo} alt={row.name} className="imageGrid" />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            gap: "5px",
            width: "100%",
          }}
        >
          <span className="eventGridText">
            {truncateText(row.name, maxLength)}
          </span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const id = Cookies.get("id");
    const token = Cookies.get("token");

    //If don't have an ID or token
    if (!id && !token) {
      navigate("/loginadmin");
      //Remove the data and forward to the Login page
      Cookies.remove("id");
      Cookies.remove("email");
      Cookies.remove("token");
      Cookies.remove("first_name");
      Cookies.remove("last_name");
      Cookies.remove("photo");
    }
    getEquipments()
      .then((res) => {
        if (res.status === 200 && res.success) {
          setEquipments(res.data);
          setEquipmentsCopy(res.data);
          setLoading(false);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, []);

  const [search, setSearch] = useState("");

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          justifyContent: "flex-end",
          marginTop:isMobile?"0px": "-50px",
          marginBottom: isMobile?"0px":"30px",
          marginRight: "0px",
        }}
      >
        <GridToolbarFilterButton
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />
        <GridToolbarExport
          slotProps={{
            button: {
              style: {
                color: "#044535",
                fontFamily: "Montserrat",
                textTransform: "none",
                fontWeight: 600,
                fontSize: "16px",
              },
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    if (search) {
      let arrayEquipments = equipmentsCopy;
      arrayEquipments = arrayEquipments.filter(
        (element) =>
          element.name.toLowerCase().includes(search.toLowerCase()) ||
          element.district_name.toLowerCase().includes(search.toLowerCase()) ||
          element.principal_modality
            .toLowerCase()
            .includes(search.toLowerCase())
      );
      setEquipments(arrayEquipments);
    } else {
      setEquipments(equipmentsCopy);
    }
  }, [search]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="background">
      <HeaderManagerWhite />
      {isMobile ? <NavManagerHorizontal /> : <NavManager />}
      <div className="topLeftBack">
        <IconButton onClick={goBack}>
        <ArrowBackIcon
            style={{ fill: "#044535", zIndex: 99, fontSize:isMobile?"20px":"25px" }}
          />
        </IconButton>
      </div>
      <div className="centerpage">
        <a className="greenTitle">{t("equipments")}</a>
        <div className="whiteBoard">
          <div
            className="col-0"
            style={{
              textAlign: "start",
              marginTop: isMobile?15:45,
              marginLeft: isMobile?10:30,
              flexDirection: "row",
              display: "flex",
              zIndex: 99999,
            }}
          >
            {/* <span
              className="addButton"
              style={{ zIndex: "1" }}
              onClick={() => {}}
            ></span> */}
            <Search1
              onSearch={(value) => {
                setSearch(value);
              }}
            />
          </div>
          <div style={{ height: "75%", maxWidth: "100%" }}>
            <ThemeProvider theme={theme}>
              <DataGrid
                className={"grid"}
                autoPageSize={true}
                rowHeight={80}
                loading={loading}
                columns={[
                  {
                    field: "equipment",
                    headerName: t("equipment"),
                    type: "string",
                    flex: 2.5,
                    renderCell: (params) => <EventCell row={params.row} />,
                  },

                  ...(isMobile
                    ? [] 
                    : [
                  {
                    field: "district_name",
                    headerName: t("district"),
                    flex: 1,
                    type: "string",
                    headerAlign:"center",
                  align:"center",
                  },
                  {
                    field: "principal_modality",
                    headerName: t("mainsport"),
                    flex: 1,
                    type: "string",
                    headerAlign:"center",
                  align:"center",
                  },
                  {
                    field: "capacity",
                    headerName: t("capacity"),
                    flex: 1,
                    type: "number",
                    headerAlign:"center",
                  align:"center",
                  }]),

                  {
                    field: "actions",
                    type: "actions",
                    headerName: t("actions"),
                    flex: 0.5,
                    cellClassName: "actions",
                    getActions: ({ id }) => {
                      return [
                        <GridActionsCellItem
                          icon={
                            <FontAwesomeIcon
                              style={{ fontSize: 18, color: "#0F1111" }}
                              icon={faPen}
                            />
                          }
                          label="Save"
                          onClick={() => {
                            navigate("/equipmentpagemanager", {
                              state: { equipmentId: id },
                            });
                          }}
                        />,
                      ];
                    },
                  },
                ]}
                sx={{
                  "& .MuiDataGrid-cell": {
                    border: "none",
                    padding: "0 !important",
                    display: "flex",
                    alignItems: "center",
                  },
                  ".MuiDataGrid-filler": {
                    display: "none",
                  },

                  "& .MuiDataGrid-row.Mui-hovered": {
                    backgroundColor: "rgba(17, 96, 48, 0.20)",
                  },

                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "rgba(17, 96, 48, 0.20)",
                  },
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  '& .MuiDataGrid-cell[data-field="local"]': {
                    fontSize: "20px",
                  },
                  '& .MuiDataGrid-cell[data-field="registered"]': {
                    fontSize: "18px",
                  },
                  "& .MuiTablePagination-root ": {
                    overflow: "hidden !important",
                  },
                }}
                rows={equipments}
                checkboxSelection={false}
                getRowClassName={() => "custom-row"}
                onRowClick={(params) => {}}
                getRowId={(row) => row._id}
                localeText={
                  i18n.language == "pt"
                    ? ptPT.components.MuiDataGrid.defaultProps.localeText
                    : i18n.language == "es"
                    ? esES.components.MuiDataGrid.defaultProps.localeText
                    : enUS.components.MuiDataGrid.defaultProps.localeText
                }
                components={{
                  BaseCheckbox: ({ value, ...otherProps }) => (
                    <Checkbox size="small" checked={value} {...otherProps} />
                  ),
                  LoadingOverlay: <CircularProgress />,
                }}
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </ThemeProvider>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              className="clientInfoEditButton"
              onClick={() => {
                navigate("/equipmentcreatemanager");
              }}
            >
              {t("addEquipment")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EquipmentsManager;
