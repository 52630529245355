import axios from "axios";
import {
  handleResponse,
  handleError,
  handleResponse2,
  handleError2,
} from "./response";
import i18next from "i18next";

//Base url

// const BASE_URL = "https://trainsport.tectank.pt:8004/api";

const BASE_URL = "http://localhost:3000/api";

// const refresh_token = Cookies.get("refresh_token");
// const token = Cookies.get("token"); 
// const language = Cookies.get("language");

// let config = {
//   headers: {
//     "Content-Type": "application/json",
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Headers":
//       "Content-Type,Access-Control-Allow-Origin, Accept",
//     "Accept-Language": i18next.language,
//   },
// };


//Pedido Get - ler dados - 9000
export const makeRequestGet = (resource) => {
  return axios
    .get(`${BASE_URL}/${resource}`)
    .then(handleResponse)
    .catch(handleError);
};

// //Pedido Get - ler dados - 8000
export const makeRequestGet2 = (resource, token) => {
  return axios
    .get(`${BASE_URL}/${resource}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": i18next.language,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};



//Pedido Post - adicionar dados - 9000
export const makeRequestPost = (resource, model, token) => {
  return axios
    .post(`${BASE_URL}/${resource}`, model, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": i18next.language,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

// //Pedido Post - adicionar dados - 8000
export const makeRequestPost2 = (resource, model, token) => {
  return axios
    .post(`${BASE_URL}/${resource}`, model, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": i18next.language,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Put - alterar dados - 9000
export const makeRequestPut = (resource, model) => {
  return axios
    .put(`${BASE_URL}/${resource}`, model)
    .then(handleResponse)
    .catch(handleError);
};

// //Pedido Put - alterar dados - 8000
export const makeRequestPut2 = (resource, model, token) => {
  return axios
    .put(`${BASE_URL}/${resource}`, model, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": i18next.language,
        Authorization: `Bearer ${token}`,
      },
    })
    .then(handleResponse)
    .catch(handleError);
};

//Pedido Delete - eliminar dados - 9000
export const makeRequestDelete = (resource, model) => {
  return axios
    .delete(`${BASE_URL}/${resource}`)
    .then(handleResponse)
    .catch(handleError);
};

// //Pedido Delete - eliminar dados - 8000
export const makeRequestDelete2 = (resource, model, token) => {
  return axios
    .delete(`${BASE_URL}/${resource}`, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers":
          "Content-Type,Access-Control-Allow-Origin, Accept",
        "Accept-Language": i18next.language,
        Authorization: `Bearer ${token}`,
      },
      data: model,
    })
    .then(handleResponse)
    .catch(handleError);
};
