import React, { useEffect, useState, useRef } from "react";

import { useNavigate } from "react-router-dom";
import "../../App.css";
import { useTranslation } from "react-i18next";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import Header from "../../components/header";
import NavClient from "../../components/navClient";
import { APIProvider, Map, Marker } from "@vis.gl/react-google-maps";
import HeaderClient from "../../components/headerClient";
import NavClientHorizontal from "../../components/navClientHorizontal";
import { Button, IconButton, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { getOffer } from "../../api/requests/Offers";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getPartnersByOfferId } from "../../api/requests/Partner";

function OfferPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const location = useLocation();
  const [partners, setPartners] = useState([]);

  const { offerId } = location.state || {};

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); 

    return () => clearTimeout(timer);
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    getOffer(offerId)
      .then((res) => {
        if (res.status === 200 && res.success) {
          let data = res.data;
          setData(data);
        } else {
          toast.error(res.error);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error("500");
      });
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getPartnersByOfferId({ offerId: offerId })
      .then((res) => {
        if (res.status === 200 && res.success) {
          setPartners(res.data.partners);
        } else {
          toast.error(res.error);
        }
      })
      .catch((error) => {
        toast.error(t("500"));
      });
  }, [offerId]);
  return (
    <div className="background">
      {isMobile ? <NavClientHorizontal /> : <NavClient />}
      <HeaderClient />
      <div className="topLeftBack">
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBackIcon
            style={{
              fill: "#044535",
              zIndex: 99,
              fontSize: isMobile ? "20px" : "25px",
            }}
          />
        </IconButton>
      </div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", 
          backgroundColor: "#f0f4f8",
          fontFamily: "Montserrat, sans-serif",
        }}
      >
        <Box
          sx={{
            width: "70%",
            maxWidth: "700px",
            backgroundColor: "#ffffff",
            borderRadius: "1rem",
            boxShadow: "0px 8px 24px rgba(0, 0, 0, 0.2)",
            padding: "3rem 2.5rem",
            textAlign: "center",
            border: "1px solid #dcdcdc",
            fontFamily: "Montserrat, sans-serif",
            marginTop:"3rem",
            height:isMobile?"50%" :"66%",
            overflowY:"auto"
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              fontWeight: "bold",
              color: "#2c3e50",
              marginBottom: "1.rem",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            {t("offerPage.specialOffer")}
          </Typography>
          <Typography
            variant="body1"
            paragraph
            sx={{
              color: "#555",
              marginBottom: "2rem",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            {data.name}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#34495e",
              fontWeight: "medium",
              marginBottom: "2rem",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            {t("offerPage.details")}: {data.obs}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              color: "#34495e",
              fontWeight: "medium",
              marginBottom: "2rem",
              fontFamily: "Montserrat, sans-serif",
            }}
          >
            {t("offerPage.discount")}: {data.discount}%
          </Typography>
          <Box
            sx={{
              maxHeight: "25vh", 
              overflowY: "auto",
              marginBottom: "2rem",
              padding: "1rem",
              border: "1px solid #ddd",
              borderRadius: "8px",
            }}
          >
            {partners.length > 0 ? (
              partners.map((partner, index) => (
                <Box
                  key={index}
                  sx={{ marginBottom: "1rem", textAlign: "left" }}
                >
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: "bold", color: "#2c3e50" }}
                  >
                    {partner.name}
                  </Typography>
                  <Typography variant="body2" sx={{ color: "#555" }}>
                    <a
                      href={partner.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "#27ae60" }}
                    >
                      {partner.link}
                    </a>
                  </Typography>
                </Box>
              ))
            ) : (
              <Typography variant="body1" sx={{ color: "#555" }}>
                {t("offerPage.noPartners")}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              marginTop: "1rem",
            }}
          >
            {/* <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{
                backgroundColor: "#27ae60",
                '&:hover': { backgroundColor: "#229954" },
                borderRadius: "8px",
                padding: "0.75rem 2rem",
                textTransform: "none",
                fontFamily: "Montserrat, sans-serif"
              }}
            >
              {t("offerPage.getOffer")}
            </Button> */}
            {/* <Button
              variant="outlined"
              color="primary"
              size="large"
              sx={{
                borderColor: "#27ae60",
                color: "#27ae60",
                "&:hover": { borderColor: "#229954", color: "#229954" },
                borderRadius: "8px",
                padding: "0.75rem 2rem",
                textTransform: "none",
                fontFamily: "Montserrat, sans-serif",
              }}
            >
              {t("offerPage.learnMore")}
            </Button> */}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default OfferPage;
